import { useNavigate } from "react-router-dom"
import "./index.css"
import { logoutUserService } from "../../Services/AlignerService"

const TokenExpired = () => {
    const navigate = useNavigate()

    const updateNavigation = () => {
        logoutUserService()
        localStorage.setItem("te", JSON.stringify(false))   // where te = token expired
        navigate("/login")
        
    }
    return (
        <div id="token-expired-w9bvlk-u8i88u" className="token-expired-container d-flex align-items-center justify-content-center">
            <div className="token-expired-sub-conntainer rounded d-flex flex-column bg-white">
                <h5 className="m-3">Session Expired</h5>
                <p className="mx-3">Your session has expired. Please log in again to continue.</p>
                <button className="token-expired-login-button border-0 text-primary px-4 py-2 rounded ms-auto mt-3 me-3" onClick={() => updateNavigation()}>Log In</button>
            </div>
        </div>
    )
}

export default TokenExpired