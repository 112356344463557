import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


const SkeletonLoader = () => {
    return (
        <div className='mt-1'>
            <SkeletonTheme baseColor="#f2fafc" highlightColor="#deeefa" >
                <p className=' mx-3 rounded'>
                    <Skeleton count={8} height={77} className='my-1' />
                </p>
            </SkeletonTheme>
        </div>
    );

}


export default SkeletonLoader