import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import { FaUserMd } from "react-icons/fa";
import close from "../../assets/Close.svg";
import {
    getAllAlignerDoctors,
    updateDentistforAlignerUer
} from "../../Services/AlignerService";

const AssingDentistPopup = (props) => {
    const { userData, assignedDentist, popupclose } = props
    const { userName, userId } = userData
    const app = JSON.parse(localStorage.getItem("xld")).app;
    const navigate = useNavigate()
    const [doctorList, setDoctorList] = useState([]);
    const [doctorId, setDoctorId] = useState(assignedDentist);
    const [loading, setloading] = useState(true);
    const initialCall = async () => {
        await getAllAlignerDoctors({ app, doctorRole: "63d93e90dae984f675862ad4"  })
            .then((res) => {
                setDoctorList(res.data.data.allDoctors);
                if  (res.data.data.allDoctors.length > 0) {
                    setDoctorId(res.data.data.allDoctors[0]._id)
                }
                setloading(false);
            })
            .catch((err) => navigate("/aligner-dashboard"));

        
    };
    useEffect(() => {
        initialCall();
    }, []);

   
    const getDoctorName = id => {
        const doctor = doctorList.find(doctorObj => doctorObj._id == id)
        const doctorName = doctor ? `Dr. ${doctor.name}` : "--"
        return doctorName
    }

    const onSubmitAssignDentist = async () => {
        await updateDentistforAlignerUer({ userId, doctorId }).then((res) => {
            popupclose()
        }).catch((err) => {
            popupclose()
        })

    }

    if (!loading) {
        return (
            <div className="model">
                <div className="model_container">
                    <div className="close_position">
                        <Button
                            onClick={() => {
                                popupclose();
                            }}
                        >
                            {" "}
                            <img src={close} width="50px" height="50px"></img>
                        </Button>
                    </div>
                    <Col md={12}>
                        <Row>
                            <Col
                                md={4}
                                className="delete_popup_height d-flex justify-content-center h-5 align-items-center"
                            >
                                <FaUserMd size="150px" color=" #F5F5FA" />
                            </Col>
                            <Col
                                md={8}
                                className="d-flex flex-column justify-content-center h-5 mt-5 align-items-start"
                            >
                                <div className="d-flex justify-content-center align-items-center mx-2 mt-4">
                                    <div className="mx-3 "> Patient Name:    </div>
                                    <div className="text-sans-bold"> {userName} </div>
                                </div>
                                <div className="d-flex justify-content-center align-items-center mx-2 mt-2 mb-4">
                                    <div className="mx-3 "> Assigned Dentist:    </div>
                                    <div className="text-sans-bold"> {getDoctorName(assignedDentist)} </div>
                                </div>


                                <div className="d-flex justify-content-center align-items-center mx-2 mt-4">
                                    <div className="mx-3 "> Dentist list  </div>
                                    <div className="text-sans-bold">
                                        {doctorList.length == 0 ? (
                                            <div className="mx-3">
                                                <span> No dentist found </span>  <Link to="/dentist" className="mx-2"> Click here </Link> <span> to add dentist</span>
                                            </div>
                                        ) : (
                                            <select
                                                className="select-doctor-input my-2 grey-bg-color"
                                                value={doctorId}
                                                onChange={(e) => {
                                                    setDoctorId(e.target.value);
                                                }}
                                            >
                                                {doctorList.map((option) => (
                                                    <option value={option._id} key={option._id}>Dr. {option.name}</option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                </div>

                                <div className="d-flex width-100 justify-content-center">
                                    <Button
                                        className="delete_popup_button mt-4"
                                        onClick={() => onSubmitAssignDentist()}
                                    >

                                        <span className="delete_button_text ">Save and Update</span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </div>
        );
    }
};

export default AssingDentistPopup;
