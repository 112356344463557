import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import SnackFire from "../../UI/Snackbar/Snackbar";
import "./alignerScans.css"
import { allAlignerUsers, updateAlignerUserProfile } from "../../Services/AlignerService";
import TokenExpired from "../TokenExpired";


const ProfileForm = ({ userData, back, reload }) => {
    const app = JSON.parse(localStorage.getItem("xld")).app;
    const user_id = userData?._id
    const appUrl = JSON.parse(localStorage.getItem("xld")).app_url
    const [loader, setLoader] = useState(false)
    const [submitButtonText, setSubmitButtonText] = useState("Submit")
    const [snackbarmessage, setsnackbarmessage] = useState("");
    const [snackbar, setsnackbar] = useState(false);
    const [type, settype] = useState("error");

    const initialValues = {
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        phone_number: userData?.phone_number,
        email: userData?.email,
    }
    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        phone_number: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
    });

    const updateUserDetailsStep1 = (data) => {
        updateAlignerUserProfile({ ...data, user_id, appUrl }).then((res) => {
            setSubmitButtonText("Updated successfully")
            setLoader(false)
            setsnackbarmessage("Profile details updated successfully");
            settype("success");
            setsnackbar(true);
            reload()
            setTimeout(() => {
                back(false)
            }, 2000);

        }).catch((err) => {
            setLoader(false)
            setsnackbarmessage("Something went wrong please try again later.");
            settype("error");
            setsnackbar(true);
        })
    }

    const handleSubmit = async (values) => {
        setLoader(true)
        const isNewEmail = userData?.email !== values.email
        if (isNewEmail) {
            // check users with this email
            allAlignerUsers({ app, email: values.email }).then((res) => {
                if (res.data.data !== "No Data") {
                    setsnackbar(true);
                    settype("error")
                    setsnackbarmessage("Email is already in use by another user.")
                    setLoader(false)

                } else {
                    updateUserDetailsStep1({ ...values, isNewEmail })
                }
            }).catch((err) => {
                setsnackbar(true);
                settype("error")
                setsnackbarmessage("Something went wrong.")
                setLoader(false)
            })

        } else {
            updateUserDetailsStep1({ ...values, isNewEmail })

        }
    }



    return (
        <div className=" mt-4">
            {snackbar && (
                <SnackFire
                    open={snackbar}
                    onClose={() => { setsnackbar(false) }}
                    position="top-right"
                    timing={4000}
                    type={type}
                    message={snackbarmessage}
                />
            )}
           {JSON.parse(localStorage.getItem("te")) && <TokenExpired />}

           <button type="button" className="bg-white rounded ms-4 border border-primary" onClick={() => { back(false) }} > <IoIosArrowBack className="mb-1" /> </button>
                              
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} >
                <Form>
                    <Container>
                        <Row className="mt-3">
                            <Col className='d-flex justify-content-center'>
                                <div className='w-75 '>
                                    <label htmlFor="first_name" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                        <span className="field-texts  mx-3">
                                            First Name<span className="text-danger">*</span>
                                        </span>
                                    </label>
                                    <Field type="text" id="first_name" name="first_name" className="form-input  grey-bg-color" placeholder="First Name" />
                                    <ErrorMessage name="first_name" component="span" className='text-danger ms-3' />
                                </div>
                            </Col>
                            <Col className='d-flex justify-content-center'>
                                <div className='w-75'>
                                    <label htmlFor="last_name" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                        <span className="field-texts  mx-3">
                                            Last Name<span className="text-danger">*</span>
                                        </span>
                                    </label>
                                    <Field type="text" id="last_name" name="last_name" className="form-input  grey-bg-color" placeholder="Last Name" />
                                    <ErrorMessage name="last_name" component="span" className='text-danger ms-3' />
                                </div>
                            </Col>

                        </Row>
                        <Row className="my-3 ">
                            <Col className='d-flex justify-content-center'>
                                <div className='w-75'>
                                    <label htmlFor="email" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                        <span className="field-texts  mx-3">
                                            Email<span className="text-danger">*</span>
                                        </span>
                                    </label>
                                    <Field type="email" id="email" name="email" className="form-input  grey-bg-color" placeholder="Email" />
                                    <ErrorMessage name="email" component="span" className='text-danger ms-3' />
                                </div>
                            </Col>
                            <Col className='d-flex justify-content-center'>
                                <div className='w-75'>
                                    <label htmlFor="phone_number" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                        <span className="field-texts  mx-3">
                                            Cell/Mobile Number<span className="text-danger">*</span>
                                        </span>
                                    </label>
                                    <Field type="text" id="phone_number" name="phone_number" className="form-input grey-bg-color" placeholder="Cell/Mobile number" />
                                    <ErrorMessage name="phone_number" component="span" className='text-danger ms-3' />
                                </div>
                            </Col>
                        </Row>
                        
                        <Row className="mt-4">
                            <Col>
                                <div className='d-flex justify-content-center mt-4'>
                                    <button type="submit" className="submit-button px-5 mb-4 border-0" >
                                        {loader ? <div className="text-center">
                                            <div className="spinner-border spinner-border-sm text-white mx-4" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div> : <p className=" m-0 d-flex align-items-center justify-content-evenly"> {submitButtonText}  </p>}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <p className="alert-info-aligncheck ps-3 rounded"> <span className="fw-bold"> Note: </span>  Other details can be updated from the user app. </p>

                        </Row>
                    </Container>

                </Form>

            </Formik>
        </div>

    )
}

export default ProfileForm