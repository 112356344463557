import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { HiOutlineInformationCircle } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { RiDashboardFill } from "react-icons/ri";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { TfiReload } from "react-icons/tfi";
import { GiProgression } from "react-icons/gi";
import { MdPending } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { MdArrowBackIos } from "react-icons/md";
import profile from "../../assets/userIcon.svg";

import dentalAnalysisimg from "../../assets/DentalAnalysis.svg";
import SnackFire from "../../UI/Snackbar/Snackbar";
import {
    getAlignerUserReports, GetBucketFile, getBinaryDataofVideo,
    updateReportViewedStatus, updateUserToViewed, userTreatmentComplete, getAlignerDetailsOfUser, getAlignerImages
} from "../../Services/AlignerService";
import ConclusionDialog from "./conclusion";
import UserDetailsPopup from "./userDetailsPopup";
import ReviewPopup from "./reviewScan";

import "../AlignerDashboard/alignerDashboard.css"
import "./alignerScans.css"
import TokenExpired from "../TokenExpired";
import Loader from "../Loader";

const AlignerReportDetails = () => {
    let { user_id } = useParams()
    const imgRef = useRef(null);
    const teethSides = ["front_teeth", "upper_jaw", "lower_jaw", "right_side", "left_side"]
    const [alignerData, setAlignerDetails] = useState({});
    const [show, setShow] = useState(false);
    const [isLoading, setLoading] = useState(true)
    const [totalReports, setTotalReports] = useState([])
    const [selectedReport, setSelectedReport] = useState()
    const [reportAdded, setReportAdded] = useState()
    const [isWithAligner, setAlignerType] = useState(true)
    const [imageSide, setImageSide] = useState("front_teeth")
    const [normalImages, setNormalImages] = useState([])
    const [alignerImages, setAlignerImages] = useState([])
    const [videoURL, setVideoURL] = useState(null);
    const [normalTags, setNormalTags] = useState()
    const [alignerTags, setAlignerTags] = useState()
    const fileOption = alignerData ? alignerData.file_option : ""
    const [snackbarmessage, setsnackbarmessage] = useState("");
    const [snackbar, setsnackbar] = useState(false);
    const [type, settype] = useState("error");
    const [reportsWithWeekId, setReportsWithWeekId] = useState([])
    const [latestReport, setLatestReport] = useState("")
    const [openConclusionDialog, setConclusionDialog] = useState(false)
    const [openProfile, setOpenProfile] = useState(false)
    const [openReviewPopup, setReviewPopup] = useState(false)

    useEffect(() => {
        apiCalls()
    }, [])

    useEffect(() => {
        selectedReport && getMedia(`${selectedReport._id}`)
    }, [reportAdded])

    const apiCalls = async () => {
        const aligner_restarts_at = await getAlignerDetails()
        await getAlignerReports(aligner_restarts_at)
    }

    const getAlignerDetails = async () => {
        let aligner_restarts_at = []
        await getAlignerDetailsOfUser(user_id).then((res) => {
            setAlignerDetails(res.data)
            if (res.data?.is_report_viewed === false) { updateUserToViewed({ user_id }) }
            aligner_restarts_at = res.data?.aligner_restarts_at ? res.data.aligner_restarts_at : []

            if (res.data.file_option === "upload") {
                getVideoDetails(res.data.video_name)
            }

        }).catch((err) => {
            console.log(err)
            setsnackbarmessage("something went wrong reading align check details")
            settype("error")
            setsnackbar(true)
        })

        return aligner_restarts_at

    }

    const getAlignerReports = async (aligner_restarts_at = []) => {

        let validReports = -1;
        let invalidReports = 0

        await getAlignerUserReports(user_id).then((res) => {
            const reports = res.data.data
            setTotalReports(reports)
            if (reports.length > 0) {
                const report = [...reports].reverse()[0]
                setSelectedReport(report)
                setReportAdded(new Date())  // updating the state to fetch report images
            }
            const reportsWithWeeks = reports.map((eachReport, index) => {
                const newFlowObj = aligner_restarts_at.find((each => each.restartsAt === (index + 1)))
                const isNewFlow = newFlowObj ? true : false

                if (eachReport.is_week_report) {
                    validReports += 1
                    invalidReports = 0
                    return { reportId: eachReport._id, weekNo: validReports, alignerNo: eachReport.aligner_number, isNewFlow }
                } else {
                    invalidReports += 1
                    return { reportId: eachReport._id, weekNo: `${validReports}.${invalidReports}`, alignerNo: eachReport.aligner_number, isNewFlow }
                }

            })
            const updatedReports = [...reportsWithWeeks].reverse()        // reverse method modifies the original array.
            setReportsWithWeekId(updatedReports)
            const latestReport = [...reports].reverse().find(eachReport => {
                if (eachReport.is_week_report) {
                    return eachReport
                }
                return ''
            })
            setLatestReport(latestReport)
            setLoading(false)

        }).catch((err) => {
            if (err?.response?.status === 409) {
                setsnackbarmessage("something went wrong retrieving scans")
                settype("error")
                setsnackbar(true)
                setLoading(false)
            }

        })

    }


    const getMedia = (report_id) => {

        getAlignerImages(report_id).then((res) => {
            makeSyncronous(res.data)
            !selectedReport.is_report_viewed && reportVeiwed(`${selectedReport._id}`)
        }).catch((err) => {
            setsnackbarmessage("Something went wrong reading images")
            settype("error")
            setsnackbar(true)
        })
    }

    const reportVeiwed = (report_id) => {
        setTotalReports(prevReports => {
            const updatedReports = prevReports.map(eachReport => {
                if (eachReport._id === report_id) {
                    return { ...eachReport, is_report_viewed: true }
                }
                return eachReport
            })
            return updatedReports

        })
        updateReportViewedStatus({ report_id })
    }

    const getVideoDetails = async (fileName) => {

        await getBinaryDataofVideo({ fileName }).then((resData) => {
            if (resData.data.data !== "No Data") {
                setVideoURL(null)
                setTimeout(() => {
                    setVideoURL(resData.data.data.videoUrl)
                }, 50)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const getImages = async (images, storage) => {
        await Promise.all(teethSides.map(async (teeth, index) => {
            const fileName = images[teeth].split("/")[1]
            const fileFolder = "toothlens-image-store/aligner-input"
            return await GetBucketFile({ fileName, fileFolder }).then((resData) => {
                if (resData.data.data !== "No Data") {
                    const byteArray = new Uint8Array(resData.data.data.data)
                    const blob = new Blob([byteArray], { type: 'image/jpeg' });
                    const imageName = teeth
                    const imageUrl = URL.createObjectURL(blob);
                    storage(prevState => [...prevState, { imageName, imageUrl }])
                    return { imageName, imageUrl }
                }
            }).catch((err) => {
                console.log(err)
            })
        }))


    }

    const getJsonData = async (normalImages, storage) => {

        const jsonDataOfImages = await Promise.all(teethSides.map(async (teeth) => {
            const fileName = `${normalImages[teeth].split("/")[1].split(".")[0]}-aligner.json`
            const fileFolder = "toothlens-image-store/aligner_json_output"
            return await GetBucketFile({ fileName, fileFolder }).then((tags) => {

                const tagsData = tags.data.data
                if (tagsData !== "No Data") {
                    return { ...tagsData, imageName: teeth, tagsData: tagsData.aligner }
                } else {
                    return { imageName: teeth, tagsData: [] }
                }
            }).catch((err) => {
                console.log("err", err)
            })
        }))
        storage(jsonDataOfImages)

    }

    const makeSyncronous = async (images) => {
        if (selectedReport) {
            const normalImages = {
                front_teeth: images.front_teeth,
                upper_jaw: images.upper_jaw,
                lower_jaw: images.lower_jaw,
                right_side: images.right_side,
                left_side: images.left_side
            }
            const alignerImages = {
                front_teeth: images.aligner_front_teeth,
                upper_jaw: images.aligner_upper_jaw,
                lower_jaw: images.aligner_lower_jaw,
                right_side: images.aligner_right_side,
                left_side: images.aligner_left_side
            }

            await getImages(alignerImages, setAlignerImages)
            await getImages(normalImages, setNormalImages)

            if (selectedReport.is_report_reviewed) {
                await getJsonData(normalImages, setNormalTags)
                await getJsonData(alignerImages, setAlignerTags)
            } else {
                setNormalTags()
                setAlignerTags()
            }
        }
    }

    const completeTreatment = async () => {
        const data = {
            reportId: selectedReport._id,
            user_id,
            is_doctor_reviewed: true,
            current_aligner: 0,
            treatment_completed: true,                        //    current aligner  == 0  which means it's a retainer
            report_go_status: 1,
            notify_mail: false

        }
        await userTreatmentComplete(data).then((res) => {
            setConclusionDialog(false)
            settype("success");
            setsnackbarmessage(res.data.message);
            setsnackbar(true);
            apiCalls()
            setTimeout(() => {
                setReviewPopup(false)
            }, 2000)


        }).catch((err) => {
            setConclusionDialog(false)
            settype("error");
            setsnackbarmessage("Something went wrong Please try agiain later");
            setsnackbar(true);
        })
    }

    const updateInfo = (scanDetails) => {
        const { reportId, is_doctor_reviewed, status_by_doctor, remarks, current_aligner, next_date_to_take_test } = scanDetails

        setAlignerDetails(prevState => {
            return { ...prevState, current_aligner, next_date_to_take_test }
        })

        setTotalReports(prevState => {
            const updatedReports = prevState.map((eachReport) => {
                if (eachReport._id === reportId) {
                    const updatedReport = { ...eachReport, is_doctor_reviewed, status_by_doctor, remarks }
                    return updatedReport
                }
                return eachReport
            })
            return updatedReports
        })

        setSelectedReport(prevReport => {
            return { ...prevReport, is_doctor_reviewed, status_by_doctor, remarks }
        })
    }


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    const calculateAge = (dobgiven) => {
        const dob = new Date(dobgiven);
        let diff_ms = Date.now() - dob.getTime();
        let age_dt = new Date(diff_ms);
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    };



    const modifyTagsToImageDimension = (tagsData, width, height) => {
        const imgRenderWidth = imgRef.current.width
        const imgRenderHeight = imgRef.current.height
        const imgNaturalWidth = width
        const imgNaturalHeight = height
        const updatedTags = tagsData.map(eachTag => {
            let { x, y, w, h } = eachTag
            x = (x * imgRenderWidth) / imgNaturalWidth;
            y = (y * imgRenderHeight) / imgNaturalHeight;
            w = (w * imgRenderWidth) / imgNaturalWidth;
            h = (h * imgRenderHeight) / imgNaturalHeight
            return { x, y, w, h }
        })

        return updatedTags
    }

    const ImageNotUsable = ({ isFalse, reason }) => {
        const unChecked = isFalse === false

        return (
            <>
                {
                    unChecked &&
                    <div className="imgage-not-uasable" >
                        <span> This image is {Array.isArray(reason) && reason.length > 0 ? reason.join(", ") : "not useable"}  </span>
                    </div>
                }
            </>
        )
    }

    const AnnotatedTags = data => {
        const { imageName } = data.imageObject
        const tagsFolder = isWithAligner ? alignerTags : normalTags
        const selectedTags = tagsFolder.find((eachTagObj) => eachTagObj.imageName === imageName)
        const { tagsData, width, height } = selectedTags
        const updatedTags = modifyTagsToImageDimension(tagsData, width, height)
        return (
            <>
                {updatedTags.map((eachtag, index) => {
                    const { x, y, w, h } = eachtag
                    const tagStyles = {
                        position: "absolute",
                        top: `${y}px`,
                        left: `${x}px`,
                        width: `${w}px`,
                        height: `${h}px`,
                        border: "3px solid red"

                    }
                    return <div key={index} style={tagStyles} >  </div>
                })}
                {selectedTags.image_useable && <ImageNotUsable isFalse={selectedTags.image_useable?.is_image_useable} reason={selectedTags?.image_useable?.reason} />}
            </>
        )

    }

    const Status = ({ status }) => {

        let updatedStatus = ''
        let styles = ''

        switch (status) {
            case 1:
                updatedStatus = "Go";
                styles = "text-success"
                break;
            case 2:
                updatedStatus = "No Go";
                styles = "text-danger"
                break;
            case 3:
                updatedStatus = "Reshoot";
                styles = "text-warning"
                break;
            default:
                break;

        }

        return (
            <span className={`${styles}  m-auto `}>{updatedStatus}</span>
        )
    }

    const EachReport = (props) => {
        const { reportId, weekNo, alignerNo, isNewFlow } = props.eachReport
        const activeReport = totalReports.find((report) => report._id === reportId)
        const { is_week_report, is_doctor_reviewed, status_by_doctor, createdAt } = activeReport
        const style = (activeReport._id === selectedReport?._id || activeReport.is_report_viewed) ? "text-primary" : "text-danger"

        return (
            <li className="" key={reportId}>
                {isNewFlow && <Row> <Col> <p className="restart-flow-element ps-3 py-1 rounded-5 " > Dentist restarted the new aligner here. </p> </Col></Row>}
                <Row className={`d-flex ${activeReport._id === selectedReport._id ? "active-report-bg-light rounded" : ""}`}>
                    <Col sm={1}> {activeReport._id === selectedReport._id && <img src={dentalAnalysisimg} className="mt-2 " width="25px" height="25px" alt="filter" />} </Col>
                    <Col sm={11} className="">
                        <button className={`test_text_style  my-1  bg-transparent ${style} d-flex justify-content-between w-100 border-0  `} onClick={() => {
                            if (activeReport !== selectedReport) {
                                setSelectedReport(activeReport)
                                setReportAdded(new Date())
                                setImageSide("front_teeth")
                                setNormalImages([])
                                setAlignerImages([])
                                setNormalTags()
                                setAlignerTags()
                            }
                            handleClose()

                        }} >
                            <Row className=" w-100 d-flex justify-content-between">
                                <Col sm={4} className=" text-start m-0">
                                    {
                                        is_week_report ? <p className="m-0 pt-1 ">  Scan {weekNo} </p>
                                            : <div> <span style={{ textSize: "25px", color: "#83b0f7" }}> Scan  {weekNo} </span>
                                                <span className="bg-transparent border-0 text-dark" data-toggle="tooltip" data-placement="top" title="Scan not taken on prescribed date">
                                                    <HiOutlineInformationCircle className="ms-1 bg-transparent" style={{ fontSize: "15px", margin: "0px 0px 3px -8px" }} />
                                                </span>
                                            </div>

                                    }
                                </Col>
                                <Col sm={1} className="d-flex justify-content-center">  {is_week_report && <p className="aligner-number badge text-bg-primary m-0 p-2 opacity-75"> {alignerNo === 0 ? "R" : alignerNo} </p>} </Col>
                                <Col sm={4} className="d-flex justify-content-center align-items-center"> <p className="m-0 text-primary font-DM-Sans">  {is_week_report && moment(new Date(createdAt)).format('YYYY-MM-DD')} </p></Col>
                                <Col sm={3} className="d-flex justify-content-center"> {is_doctor_reviewed && is_week_report && <Status status={status_by_doctor} />} </Col>
                            </Row>
                        </button>
                    </Col>
                </Row>

            </li>

        )
    }

    const ActiveImage = () => {
        const imageFolder = isWithAligner ? alignerImages : normalImages
        const imageObject = imageFolder.find(eachObj => eachObj.imageName === imageSide)
        const imageUrl = imageObject?.imageUrl
        // const sampleImage = "https://source.unsplash.com/user/c_v_r/1900x800"
        return (
            <>
                {
                    imageUrl ?
                        <div className="active-image-container image-container">
                            <img src={imageUrl} alt="report" ref={imgRef} width="100%" height="100%" className="rounded" />
                            {normalTags && alignerTags && imgRef.current && <AnnotatedTags imageObject={imageObject} />}
                        </div> : <TeethAnimation />

                }
            </>
        )
    }

    const TeethAnimation = () => {
        return (
            <Col>
                {
                    selectedReport ?
                        <div className="active-image-container  image-container d-flex flex-column align-items-center justify-content-center ">
                            <div className="sub-container">
                                <img src={process.env.PUBLIC_URL + '/loading-icon.svg'} alt="LoadingIcon" />
                            </div>
                            <div className="loader mb-5">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div> : <div className="active-image-container border d-flex justify-content-center align-items-center ">
                            <p className="text-primary fs-4">User haven't shared their report yet.</p>
                        </div>
                }

            </Col>
        )
    }


    const Header = () => {
        return (
            <div className="aligner-scan-header-container d-flex justify-content-between p-2 shadow-sm">
                <Link to="/aligner-dashboard" style={{ textDecoration: "none", color: "inherit" }}>
                    <button type="button" className=" back-to-dashboard-button d-flex mx-2 rounded border-0 py-2 d-flex align-items-center justify-content-center">
                        <RiDashboardFill className="fs-3 text-primary" />
                        <span className="font-DM-Sans ms-2">Dashboard </span>
                    </button>
                </Link>
                <h3 className="aligner-scan-header text-primary"> Scan Records </h3>
                <p className="aligner-header-empty-element"></p>
            </div>

        )
    }

    const Recommendation = ({ status }) => {
        let text = 'Pending'
        let styles = 'aligncheck-reviewed-pending'

        switch (status) {
            case 1:
                text = "Go";
                styles = "aligncheck-reviewed-go"
                break;
            case 2:
                text = "No Go";
                styles = "aligncheck-reviewed-no-go"
                break;
            case 3:
                text = "Reshoot";
                styles = "aligncheck-reviewed-reshoot"
                break;
            default:
                text = "Pending";
                styles = "aligncheck-reviewed-pending"
                break;

        }

        return (
            <div className="d-flex flex-column align-items-center">
                <span style={{ fontSize: "15px" }}>Recommendation </span>
                <span className={`${styles}  px-2 rounded-fill  fw-bold text-center pt-1 rounded-pill`} style={{ width: "100px", fontSize: "17px" }}> {text}  </span >
            </div>

        )

    }

    const Progress = () => {
        const { treatment_completed, current_aligner, total_aligners } = alignerData
        const percentage = treatment_completed ? 100 : Math.floor((current_aligner / total_aligners) * 100)
        return (
            <div className={`my-2 py-2 d-flex justify-content-between align-items-center status-bar-container-progress rounded ms-auto `} >
                <span className={`status-icon-container text-center m-0 `}>  <GiProgression /> </span>
                <div className="d-flex flex-fill align-items-center justify-content-evenly">
                    <div className="flex-fill pe-3 ms-2  ">
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{ width: `${percentage}%` }} aria-valuemin="0" aria-valuemax="100">  </div>
                        </div>
                        <p className="font-size-13 text-dark font-DM-Sans m-0"> Next check: <span className="text-primary fw-bold ms-1"> {moment(new Date(alignerData?.next_date_to_take_test)).format("YYYY-MM-DD")} </span> </p>
                    </div>
                </div >
                <p className="m-0 fw-bold"> {`${current_aligner}/${total_aligners}`} </p>
                <span className={`mx-3  px-4 py-1 rounded-pill fw-bold patient-as-progress text-white`}> Current aligner {alignerData?.current_aligner} </span>
            </div>
        )
    }

    const StatusBar = ({ report }) => {
        const isReviewed = report?.is_doctor_reviewed
        let icon = <MdPending />
        let style = "pending"
        let text = "Not reviewed"
        if (isReviewed) {
            const number = report?.status_by_doctor
            switch (number) {
                case 1:
                    icon = <FaRegCheckCircle />
                    style = "go"
                    text = "Go"
                    break;
                case 2:
                    icon = <MdCancel />
                    style = "no-go"
                    text = "NoGo"
                    break;
                case 3:
                    icon = <TfiReload />
                    style = "reshoot"
                    text = "Reshoot"
                    break;
                default:
                    break;
            }
        }
        return (
            <div className={`my-2 py-2 d-flex justify-content-between align-items-center status-bar-container-${style} rounded `}>
                <span className={`status-icon-container text-center m-0 `}>  {icon} </span>
                <span className="flex-fill ms-2"> Scanned with <span className={`fw-bold doctor-as-${style} px-2 py-1 rounded-3 mx-1 d-inline-block`}>  {selectedReport?.aligner_number === 0 ? `Retainer` : `Aligner number ${selectedReport?.aligner_number}`}  </span>on {moment(new Date(selectedReport?.createdAt)).format('YYYY-MM-DD')}. </span>
                <span className={`mx-3   px-4 py-1 rounded-pill fw-bold doctor-as-${style} text-center`}> {text} </span>
            </div>
        )
    }

    const moveBackward = () => {
        const currentIndex = teethSides.indexOf(imageSide)
        const prevImage = teethSides[currentIndex - 1]
        setImageSide(prevImage)

    }

    const moveForward = () => {
        const currentIndex = teethSides.indexOf(imageSide)
        const nextImage = teethSides[currentIndex + 1]
        setImageSide(nextImage)
    }


    return (
        <div className="aligncheck-scan-details-page-container">
            <Header />
            {JSON.parse(localStorage.getItem("te")) && <TokenExpired />}
            {
                isLoading ? <Loader /> :
                    <>
                        <>
                            <Offcanvas show={show} onHide={handleClose} width="500px !important">
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title> <span className="patient-summary fs-4"> Scan History </span></Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>

                                    <Row>
                                        <Col md={12} className="">
                                            <Row className="mb-2 scan-container">
                                                <Col sm={4} > <p className="text-end pe-4 me-3"> Scans </p>   </Col>
                                                <Col sm={2} > <p className="text-center ms-2"> Aligner </p>   </Col>
                                                <Col sm={2} > <p className="text-end"> Date </p></Col>
                                                <Col sm={3} > <p className="text-end"> Status </p> </Col>
                                            </Row>
                                            {
                                                reportsWithWeekId.length > 0 ?
                                                    <ul className="list-unstyled ms-3">
                                                        {alignerData.treatment_completed && <p className="treatment-complete-tag ps-3 py-1 rounded-5 ">Aligner treatment completed</p>}
                                                        {reportsWithWeekId.map((eachReport, index) => <EachReport key={index} eachReport={eachReport} index={index} />)}
                                                    </ul> : <p className="text-center">User haven't shared reports yet.</p>
                                            }
                                        </Col>

                                    </Row>

                                </Offcanvas.Body>
                            </Offcanvas>
                        </>
                        <Container fluid>
                            <Row className="my-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <button type="button" className="view-scan-button font-DM-sans rounded py-1 px-2 bg-primary text-white" onClick={handleShow}> View Scans </button>
                                        <button type="button" className="aligncheck-profile-icons me-2 mb-1 text-primary" onClick={() => { setOpenProfile(true) }}>  <img src={profile} width="38px" alt="close" height="38px"></img>  </button>
                                    </div>
                                    <div className="d-flex ">
                                        <div className="d-flex py-1  px-4 my-2 me-1 d-flex justify-content-between aligncheck-image-sides-container rounded">
                                            <button type="button" className={` aligner-img-side-button me-2 ${isWithAligner ? "text-white bg-primary  " : ""}`} onClick={() => { setAlignerType(true) }}> Images with aligner</button>
                                            <button type="button" className={` aligner-img-side-button ${!isWithAligner ? "text-white bg-primary " : ""}`} onClick={() => { setAlignerType(false) }}> Images without aligner</button>
                                        </div>
                                    </div>
                                    <div className="me-3">
                                        {selectedReport ? (selectedReport?.is_report_reviewed ? <Recommendation status={selectedReport?.status_by_annotator} /> : <Recommendation status={"pending"} />) : null}
                                    </div>
                                </div>
                            </Row>

                            <Row >
                                <Col className="rounded d-flex justify-content-center align-items-center active-image-container bg-white">
                                    {
                                        fileOption === "upload" ?
                                            videoURL ? (
                                                <div className="h-100 ">

                                                    <video controls autoPlay width="100%" height="100%">
                                                        <source src={videoURL} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                            ) : (
                                                <p>Loading video...</p>
                                            )
                                            :
                                            <div className="w-100 h-100 ">
                                                <iframe src={alignerData.web_url}         // src="https://www.toothlens.com"
                                                    title="plan file"
                                                    width="100%"
                                                    height="100%"
                                                    style={{ border: 'none' }}></iframe>
                                            </div>
                                    }
                                </Col>
                                <Col className="bg-white">
                                    <div className="active-image-container image-container">
                                        {
                                            selectedReport ?
                                                <>
                                                    <ActiveImage />
                                                    <div className="slider-buttons-container">
                                                        {
                                                            teethSides.map((eachSide, index) => {
                                                                const isActive = eachSide === imageSide
                                                                return (
                                                                    <button key={index} className={`circle-buttons mx-1 ${isActive ? "circle-active-button" : ""} `} onClick={() => { setImageSide(eachSide) }} > </button>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <button className={`aligncheck-image-move-buttons backward-button border-0 ${imageSide === "front_teeth" ? "no-drop" : ""}`} onClick={moveBackward} disabled={imageSide === "front_teeth"}>
                                                        <MdArrowBackIos className="m-0" />
                                                    </button>
                                                    <button className={`aligncheck-image-move-buttons forward-button border-0 ${imageSide === "left_side" ? "no-drop" : ""}`} onClick={moveForward} disabled={imageSide === "left_side"}>
                                                        <MdArrowForwardIos />
                                                    </button>
                                                </>
                                                :
                                                <div className="h-100 d-flex align-items-center justify-content-center"> <p className="text-primary fs-4">User haven't shared their report yet.</p> </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex ">
                                    {!alignerData?.treatment_completed ? <Col className="pe-2">   <Progress /> </Col> : <Col>  </Col>}
                                    {!alignerData?.treatment_completed && selectedReport ? <Col className="ps-2"> <StatusBar report={selectedReport} />  </Col> : <Col> </Col>}
                                </Col>
                                {alignerData?.treatment_completed && <Col>
                                    <div className="aligncheck-reviewed-go w-100 py-3 text-center mt-2 rounded">
                                        User have successfully completed the treatment.
                                    </div> </Col>}
                            </Row>
                            <Row>
                                <Col> </Col>
                                <Col>
                                    <div className="d-flex justify-content-end">
                                        {!alignerData?.treatment_completed && <button type="button" className="btn btn-primary me-4 px-4 rounded" onClick={() => { setReviewPopup(true) }}> Review </button>}
                                    </div>
                                </Col>
                            </Row>
                            {snackbar && (
                                <SnackFire
                                    open={snackbar}
                                    onClose={() => { setsnackbar(false) }}
                                    position="top-right"
                                    timing={4000}
                                    type={type}
                                    message={snackbarmessage}
                                />
                            )}
                            {openConclusionDialog && (
                                <ConclusionDialog
                                    open={openConclusionDialog}
                                    completeTreatment={completeTreatment}
                                    setOpen={(result) => setConclusionDialog(result)} />
                            )}
                            {openProfile && <UserDetailsPopup open={openProfile} alignerData={alignerData} setOpen={setOpenProfile} reloadAligner={getAlignerDetails} reloadUserDetails={apiCalls} noOfReports={totalReports.length} />}
                            {openReviewPopup && <ReviewPopup setOpen={setReviewPopup} alignerData={alignerData} totalReports={totalReports} selectedReport={selectedReport} reloadUserDetails={updateInfo} latestReport={latestReport} setConclusionDialog={setConclusionDialog} />}
                        </Container>

                    </>
            }
        </div>
    )
}

export default AlignerReportDetails