import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { CgProfile } from "react-icons/cg";
import { TbGenderBigender } from "react-icons/tb";
import { IoIosMail } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import moment from "moment";
import { RiPhoneFill } from "react-icons/ri";
import ProfileForm from './ProfileUpdateForm';
import AlignerUpdateForm from './alignerUpdateForm';
import RestartPlan from './restartUser';
import close from "../../assets/Close.svg";
import "./alignerScans.css"
import TokenExpired from '../TokenExpired';
import { getAlignerUserDetails } from '../../Services/AlignerService';
const UserDetailsPopup = ({ open, alignerData, setOpen, reloadAligner, reloadUserDetails, noOfReports }) => {

  let { user_id } = useParams()

  const tabs = {
    profile: "profile",
    alignerDetails: "alignerDetails",
    restartAligner: "restarAligner"
  }

  const [selectedTab, setSelectedTab] = useState(tabs.profile)
  const [profile, setProfile] = useState()
  const [profileLoading, setLoading] = useState(true)
  const [openProfileForm, setProfileForm] = useState(false)
  const [openAlignerForm, setAlignerForm] = useState(false)
  const [openRestartAlignerForm, setRestartAlignerForm] = useState(false)

  useEffect(() => {
    getUserProfile()
  }, [])

  const getUserProfile = () => {
    setProfile()
    setLoading(true)
    getAlignerUserDetails(user_id).then((res) => {
      setProfile(res.data.data)
      setLoading(false)
    }).catch((err) => {
      console.log(err)
      setLoading(false)
    })
  }

  const AlignerDetails = () => {
    const planFile = alignerData?.file_option === "upload" ? "mp4 file added" : <a href={`${alignerData?.web_url}`} target='_blank' rel="noreferrer"> {alignerData?.web_url}</a>;
    return (
      <div className="aligner-details-main-container d-flex flex-column align-items-center justify-content-center">
        <div className=" aligner-profile-details-sub-container d-flex flex-column bg-white  p-3 px-5 shadow rounded" style={{ width: "500px" }}>
          <p>Total Aligners : <span className=' ms-4 text-primary fw-bold'> {alignerData?.total_aligners}  </span> </p>
          <p>Current Aligner :  <span className=' ms-3 text-primary fw-bold'>    {alignerData?.current_aligner} </span></p>
          <p>Next Check Date : <span className=' ms-2 text-primary fw-bold'>  {moment(new Date(alignerData?.next_date_to_take_test)).format('YYYY-MM-DD')}  </span></p>
          <p>Aligner Change Period: <span className='ms-3 text-primary fw-bold'> {`${alignerData?.aligner_change_days} days`} </span></p>
          <div className='d-flex' >  <span className='text-nowrap' > Plan File :  </span> <p className=' ms-3 text-primary fw-bold text-truncate' >   {planFile}  </p> </div>
        </div>
        <button type="button" className="submit-button border-0 bg-primary text-white rounded px-4 py-1 mt-3" onClick={() => { setAlignerForm(true) }}> <CiEdit style={{ fontSize: "22px" }} />   Update</button>
      </div>
    )
  }

  const ProfileInfo = () => {
    return (

      <div className="aligner-profile-details-main-container d-flex flex-column text-primary align-items-center justify-content-center  ">
        {
          profileLoading ?
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div> : profile ?
              <> <div className=" aligner-profile-details-sub-container d-flex flex-column w-50">
                <p className="details-item-element rounded p-1 ps-0 my-1 "> <span className="profile-details-icon-container text-white bg-primary me-2">  <CgProfile />  </span>  {`${profile.first_name} ${profile.last_name}`} </p>
                <p className="details-item-element rounded p-1 ps-0 my-1"> <span className="profile-details-icon-container text-white bg-primary me-2"> <TbGenderBigender /> </span>  {profile.gender}</p>
                <p className="details-item-element rounded p-1 ps-0 my-1 pe-3"> <span className="profile-details-icon-container text-white bg-primary me-2"> <IoIosMail />  </span>  {profile.email} </p>
                <p className="details-item-element rounded p-1 ps-0 my-1"><span className="profile-details-icon-container text-white bg-primary me-2">  <RiPhoneFill /> </span>  {profile.phone_number}</p>
              </div>
                <button type="button" className="submit-button border-0 bg-primary text-white rounded px-4 py-1 mt-3" onClick={() => { setProfileForm(true) }}> <CiEdit style={{ fontSize: "22px" }} />   Update</button>
              </> : <p >Something went wrong</p>
        }
      </div>
    )
  }

  const RestartDescription = () => {
    return (
      <div className='d-flex flex-column align-items-end justify-content-evenly mt-5' style={{ height: "340px" }}>
        <p className='px-5 fs-5'>
          "If the current aligners are not achieving the desired results, initiate a fresh journey using the 'Restart feature' for a  new set of aligners and new plan files."
        </p>
        <button type="button" className="submit-button border-0 bg-primary text-white rounded px-4 py-1 mt-5 ml-auto me-5" onClick={() => { setRestartAlignerForm(true) }}> Restart </button>
      </div>
    )
  }

  return (
    <div className='aligner-profile-popup-main-container d-flex justify-content-center align-items-center' >
      <div className='aligner-profile-popup-sub-container rounded-3'>
        {JSON.parse(localStorage.getItem("te")) && <TokenExpired />}

        <div className='d-flex mt-3'>
          <div className='profile-tabs-container bg-white rounded px-3  d-flex mx-4'>
            <button type="button" className={`flex-fill profile-toggle-button m-1 p-1 rounded ${selectedTab === tabs.profile ? "bg-primary text-white" : ""}`} onClick={() => { setSelectedTab(tabs.profile) }} > Profile</button>
            <button type="button" className={`flex-fill profile-toggle-button m-1 p-1 rounded ${selectedTab === tabs.alignerDetails ? "bg-primary text-white" : ""}`} onClick={() => { setSelectedTab(tabs.alignerDetails) }}>Aligner Details </button>
            <button type="button" className={`flex-fill profile-toggle-button m-1 p-1 rounded ${selectedTab === tabs.restartAligner ? "bg-primary text-white" : ""}`} onClick={() => { setSelectedTab(tabs.restartAligner) }}>Restart Aligner </button>
          </div>
          <button type="button" className='bg-transparent border-0 ' onClick={() => { setOpen(false) }}>
            <img src={close} width="50px" alt="close" height="35px"></img>
          </button>
        </div>

        <div className='profile-popup-container' >

          {selectedTab === tabs.profile && (
            <div className='aligner-profile-container'>
              {openProfileForm ? <ProfileForm userData={profile} back={setProfileForm} reload={getUserProfile} /> : <ProfileInfo />}
            </div>
          )}
          {selectedTab === tabs.alignerDetails &&
            (<div>
              {openAlignerForm ? <AlignerUpdateForm alignerData={alignerData} back={setAlignerForm} reload={reloadAligner} /> : <AlignerDetails />}
            </div>
            )}
          {selectedTab === tabs.restartAligner && (
            <div>
              {openRestartAlignerForm ? <RestartPlan alignerData={alignerData} back={setRestartAlignerForm} reload={reloadAligner} noOfReports={noOfReports} /> : <RestartDescription />}
            </div>
          )}

        </div>
      </div>
    </div>
  );
}

export default UserDetailsPopup