import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import moment from "moment";
import * as Yup from 'yup';
import close from "../../assets/Close.svg";
import { FaUserPlus } from "react-icons/fa";
import Button from "@mui/material/Button";
import { signUpNewUser, uploadVideoTos3Bucket, uploadFilesToS3Bucket } from '../../Services/AlignerService';
import { IoCloudUploadSharp } from "react-icons/io5";
import { MdCancel, MdAddCircleOutline } from "react-icons/md";

import SnackFire from '../../UI/Snackbar/Snackbar';
import "./alignerDashboard.css"


import "./alignerDashboard.css"
import TokenExpired from '../TokenExpired';
import { getDoctors } from '../../Store/doctors';

const SignUpForm = (props) => {
    const { popupclose, reload } = props
    const dispatch = useDispatch()
    const app = JSON.parse(localStorage.getItem("xld")).app;
    const appUrl = JSON.parse(localStorage.getItem("xld")).app_url
    const { doctors, isLoading, fetched } = useSelector((state) => state.doctors)
    
    const [snackbarmessage, setsnackbarmessage] = useState("");
    const [snackbar, setsnackbar] = useState(false);
    const [type, settype] = useState("error");
    const [loader, setLoader] = useState(false)
    const [submitButtonText, setSubmitButtonText] = useState("Add User")


    useEffect(() => {
        !fetched && dispatch(getDoctors({ app, doctorRole: "63d93e90dae984f675862ad4" }))
    }, [])


    const getCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const date = currentDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${date}`
    }


    const initialValues = {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        treatmentDate: getCurrentDate(),
        assignedDentist: "",
        dob: '',
        zipcode: '',
        gender: '',
        fileOption: "",
        file: "",
        webUrl: "",
        newUser: true,
        current_aligner: 0,
        next_date_to_take_test: new Date(),
        aligner_change_days: 15,
        total_aligners: "",
        aligner_restarts_at: [],
        movement_chart: '',
        pre_treatment_photos: {},
        first_set_of_aligners: {},
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        mobileNumber: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        treatmentDate: Yup.string().required('Required'),
        assignedDentist: Yup.string().required('Required'),
        dob: Yup.string().required('Required'),
        zipcode: Yup.string().required('Required'),
        gender: Yup.string().required('Required'),
        fileOption: Yup.string().required('Required'),
        file: Yup.mixed().when('fileOption', {
            is: 'upload',
            then: Yup.mixed().required('Please upload a file.').test('is-video', 'Invalid file format. Please upload a mp4 file.', (value) =>
                value && ['video/mp4', 'video/mpeg', 'video/ogg', 'video/webm', 'video/quicktime'].includes(value.type)
            ).test('fileSize', 'Video size is too large, please select a video of below 150 mb', (value) => !value || value.size <= 150 * 1024 * 1024),
        }),
        webUrl: Yup.mixed().when('fileOption', {
            is: 'webUrl',
            then: Yup.string().required('URL is required').url('Invalid URL. Please enter a valid URL.'),
        }),
        next_date_to_take_test: Yup.mixed().when('newUser', {
            is: false,
            then: Yup.string()
        }),
        aligner_change_days: Yup.number().min(1, "Must be more than 0").required("Required"),
        total_aligners: Yup.number().min(1, "Must be more than 0").required("Required"),
        current_aligner: Yup.number().min(0, "Must be positive").required("Required")
    });

    const uploadFilesTos3Bucket = async (email, chart, PreTreatmentPhotos, alignerPhotos) => {
        const formData = new FormData()
        const properties = []
        if (chart) {
            formData.append("chart", chart)
            properties.push("chart")
        }

        for (const property in PreTreatmentPhotos) {
            if (PreTreatmentPhotos.hasOwnProperty(property) && PreTreatmentPhotos[property]) {
                formData.append(property, PreTreatmentPhotos[property]);
                properties.push(property + '')

            }
        }

        for (const key in alignerPhotos) {
            if (alignerPhotos.hasOwnProperty(key) && alignerPhotos[key]) {
                formData.append(key, alignerPhotos[key]);
                properties.push(key + '')
            }
        }

        if (properties.length > 0) {
            const data = await uploadFilesToS3Bucket({ email, app, formData }).then((res) => {
                if (JSON.stringify(res.data.data) === '{}') {
                    setLoader(false)
                    setsnackbarmessage(res.data.message);
                    settype("error");
                    setsnackbar(true);
                    return []
                } else {
                    return res.data.data
                }
            }).catch((err) => {
                console.log("error", err)
                return []
            })

            return data

        }
        return []


    }

    const help = () => {
        setTimeout(() => {
            setsnackbarmessage("Please try adding the valid details.")
            settype("error")
            setsnackbar(true)
        }, 2000);
    }

    const handleSubmit = async (values, { setSubmitting }) => {
        const { fileOption, file } = values
        setLoader(true)
        setSubmitting(true);
        const email = values.email
        const chart = values.movement_chart
        const PreTreatmentPhotos = values.pre_treatment_photos
        const alignerPhotos = values.first_set_of_aligners
        const files = await uploadFilesTos3Bucket(email, chart, PreTreatmentPhotos, alignerPhotos)

        if (fileOption == "upload" && file) {
            // converting video file to required file format to store in s3Bucket

            const blob = new Blob([file], { type: file.type });
            const newFile = new File([blob], file.name, { type: file.type, lastModified: file.lastModified });
            const formData = new FormData();
            formData.append('video', newFile);
            formData.append("userEmail", values.email)
            const userEmail = values.email
            uploadVideoTos3Bucket({ formData, app, userEmail }).then((res) => {
                if (JSON.stringify(res.data.data) === '{}') {
                    setLoader(false)
                    setsnackbarmessage(res.data.message);
                    settype("error");
                    setsnackbar(true);

                } else {
                    const videoDetails = res.data.data
                    signUpNewUser({ ...values, ...videoDetails, app, appUrl, files }).then((res) => {
                        setsnackbarmessage(res.data.message);
                        if (JSON.stringify(res.data.data) === '{}') {
                            settype("error")

                        } else {
                            settype("success");
                            setSubmitButtonText("User Added Successfully")
                            setTimeout(() => {
                                popupclose();
                                reload()
                            }, 5000);

                        }
                        setLoader(false)
                        setsnackbar(true);
                    }).catch((err) => {
                        setLoader(false)
                        setsnackbarmessage("Adding the user failed");
                        settype("error")
                        setsnackbar(true);
                        help()

                    })
                }
            }).catch((err) => {
                setLoader(false)
                console.log("error")
                setsnackbarmessage("Failed uploading the mp4 file")
                settype("error")
                setsnackbar(true)

            })
        } else {
            setLoader(true)
            signUpNewUser({ ...values, app, appUrl, files }).then((res) => {
                setsnackbarmessage(res.data.message);
                if (JSON.stringify(res.data.data) === '{}') {
                    settype("error")

                } else {
                    settype("success");
                    setSubmitButtonText("User Added Successfully")
                    setTimeout(() => {
                        popupclose();
                        reload()
                    }, 5000);
                }
                setLoader(false)
                setsnackbar(true);
            }).catch((err) => {
                console.log(err.response.data.message)
                setLoader(false)
                setsnackbarmessage("Adding the user failed");

                settype("error")
                setsnackbar(true);
                help()

            })
        }



    }

    const handleclose = () => {
        popupclose();
        reload();
    }


    const updateForm = (values, setFieldValue) => {
        const alignerDays = values.aligner_change_days ? values.aligner_change_days : 1
        const startDate = moment(new Date(values.treatmentDate))
        const signUpDate = moment(new Date())
        const noOfPastDays = signUpDate.diff(startDate, 'days')

        if (noOfPastDays > 0) {

            const reportsCompleted = Math.floor(noOfPastDays / alignerDays)
            const lastReport = (reportsCompleted * alignerDays) + alignerDays
            const today = new Date(values.treatmentDate)
            today.setDate(today.getDate() + (lastReport - alignerDays))
            const sameDay = moment(new Date(new Date())).format('MM/DD/YYYY') === moment(new Date(today)).format('MM/DD/YYYY')

            let nextReportDate = new Date(values.treatmentDate)
            nextReportDate.setDate(nextReportDate.getDate() + lastReport)

            if (sameDay) {
                nextReportDate = today
            }

            setFieldValue('newUser', false)
            setFieldValue('next_date_to_take_test', nextReportDate)
        } else {
            setFieldValue('newUser', true)
            setFieldValue('next_date_to_take_test', values.treatmentDate)
        }

    }

    const TreatmentDate = () => {
        const { values, setFieldValue } = useFormikContext()

        useEffect(() => {
            updateForm(values, setFieldValue)

        }, [values.treatmentDate])

        return (
            <Col className='d-flex justify-content-center'>
                <div className='w-75'>
                    <label htmlFor="treatmentDate" className="pt-2 font-weight-500 text-dark field-text mb-1">
                        <span className="field-texts  mx-3">
                            Treatment Start Date<span className='text-danger'>*</span>
                        </span>
                    </label>
                    <Field type="date" id="treatmentDate" name="treatmentDate" className="form-input  grey-bg-color" placeholder="Treatment Start Date" />
                    <ErrorMessage name="treatmentDate" component="div" className='text-danger ms-3' />
                </div>
            </Col>

        )
    }

    const AlignerDays = () => {
        const { values, setFieldValue } = useFormikContext()

        useEffect(() => {
            updateForm(values, setFieldValue)

        }, [values.aligner_change_days])


        return (
            <Col className='d-flex justify-content-center'>
                <div className='w-75'>
                    <label htmlFor="aligner_change_days" className="pt-2 font-weight-500 text-dark field-text mb-1">
                        <span className="field-texts  mx-3">
                            Aligner Change Period(days)<span className='text-danger'>*</span>
                        </span>
                    </label>
                    <Field type="number" min="1" id="aligner_change_days" name="aligner_change_days" className="form-input  grey-bg-color" placeholder="Number of days" />
                    <ErrorMessage name="aligner_change_days" component="div" className='text-danger ms-3' />
                </div>
            </Col>

        )
    }

    const NextCheckDate = () => {
        const { values, setFieldValue } = useFormikContext()
        let nextReport = moment(new Date(new Date())).format('MM/DD/YYYY') === moment(new Date(values.next_date_to_take_test)).format('MM/DD/YYYY') ? "Today" : moment(new Date(values.next_date_to_take_test)).format('DD/MM/YYYY')
        useEffect(() => {
            nextReport = moment(new Date(new Date())).format('MM/DD/YYYY') === moment(new Date(values.next_date_to_take_test)).format('MM/DD/YYYY') ? "Today" : moment(new Date(values.next_date_to_take_test)).format('DD/MM/YYYY')

        }, [values.treatmentDate, values.aligner_change_days])
        return (
            <>
                {

                    <Col className='text-center field-texts  mx-4 mt-4'>
                        <div>Next Check is on <span className="text-primary ms-1">
                            {nextReport}
                        </span> </div>
                    </Col>
                }
            </>
        )
    }


    const UploadFileInput = () => {
        const { values, setFieldValue } = useFormikContext();
        return (
            <>
                {values.fileOption === 'upload' && (
                    <div className=' d-flex flex-column justify-content-center align-items-center '>
                        <label htmlFor="file" className=' file-input bg-primary text-white rounded border border-primary py-1 px-3 ms-3 mt-4'>Browse a mp4 file   </label>
                        <input
                            type="file"
                            id="file"
                            name="file"
                            onChange={(event) => setFieldValue('file', event.currentTarget.files[0])}
                            accept="video/*"
                            className='hide-input-plan-file-element'
                        />
                        {values.file && values.file.name && <p className='text-success fw-bold'>  {values.file.name}  </p>}
                        <ErrorMessage name="file" component="div" className="text-danger" />
                        {!values.file && <p className='text-danger'>No File choosen</p>}
                    </div>
                )
                }

                {values.fileOption === "webUrl" && (
                    <div className='w-75'>
                        <label htmlFor="webUrl" className="pt-2 font-weight-500 text-dark field-text mb-1">
                            <span className="field-texts  mx-3">
                                Web URL<span className='text-danger'>*</span>
                            </span>
                        </label>
                        <Field type="text" id="webUrl" name="webUrl" className="form-input grey-bg-color" placeholder="add your URL here" />
                        <ErrorMessage name="webUrl" component="div" className='text-danger ms-3' />
                    </div>
                )
                }
            </>
        );
    };

    const MovementChart = () => {
        const { values, setFieldValue } = useFormikContext()
        return (
            <>
                <Col className=''>
                    <div className='d-flex flex-column align-items-start ms-2'>
                        <label htmlFor="movement_chart" className="pt-2 font-weight-500 text-dark field-text mb-1 ms-4">
                            <span className="field-texts  mx-4">
                                Movement chart (pdf or excel)
                            </span>
                        </label>
                        <div className='d-flex align-items-center justify-content-center'>
                            <label htmlFor="movement_chart" className='ms-2 file-input bg-primary text-white rounded border border-primary py-1 px-3 ms-5'>Browse a Chart <IoCloudUploadSharp className='upload-icon' /></label>
                            <input type='file'
                                id="movement_chart"
                                name="movement_chart"
                                accept=".pdf, .xlsx, .xls"
                                className='hide-input-plan-file-element'
                                onChange={(event) => {
                                    const file = event.target.files[0];
                                    setFieldValue('movement_chart', file);
                                }}
                            />
                            {values.movement_chart && values.movement_chart.name && <p className='text-success ms-3 mt-2 '> <span className='text-dark'> Selected: </span> <span className='fw-bold'> {values.movement_chart.name}   </span>   </p>}
                            {values.movement_chart && values.movement_chart.name && <button type='button' className='bg-white border-0' onClick={() => { setFieldValue('movement_chart', '') }} > <MdCancel className='mb-4 shadow' />  </button>}
                        </div>
                    </div>
                </Col>
            </>
        )
    }


    const PreTreatmentPhotos = () => {
        const { values, setFieldValue } = useFormikContext()
        const teethSides = ["front_teeth", "upper_jaw", 'lower_jaw', 'right_side', 'left_side']

        const removeSelectedImage = side => {
            setFieldValue(`pre_treatment_photos.${side}`, "")
        }

        const addPreTreatmentPhotos = (eachSide, event) => {
            let file = event.target.files[0]
            const maxSize = 150 * 1024 * 1024; // max 150mb
            if (file && file.size > maxSize) {
                alert('File size should be less than 150mb');
                file = '';
            }
            const property = `pre_treatment_photos.${eachSide}`
            setFieldValue(property, file)

        }
        return (
            <Col>
                <div className='mt-3'>
                    <p className="text-center field-texts  mx-3">Add pre-treatment photos (Optional) </p>
                    <div className='d-flex justify-content-between align-items-center mx-4 border border-3 bg-light  rounded-2 p-3'>
                        {
                            teethSides.map(eachSide => {
                                return (
                                    <div key={eachSide} className=' '>
                                        {
                                            values.pre_treatment_photos[eachSide] ?
                                                <div className='selected-active-image-container rounded'>
                                                    <button type="button" className="deleted-icon-of-image border-0 p-0 m-0" onClick={() => removeSelectedImage(eachSide)}> <MdCancel />  </button>
                                                    <img src={URL.createObjectURL(values.pre_treatment_photos[eachSide])} alt={eachSide} className='rounded' />
                                                </div> :
                                                <div className='selected-image-empty-container rounded'>
                                                    <p className='text-center text-capitalize'> {eachSide.split("_").join(" ")} </p>
                                                    <label htmlFor={eachSide} className='add-image-plus-icon'> <MdAddCircleOutline />  </label>
                                                    <input
                                                        type="file"
                                                        id={eachSide}
                                                        name={eachSide}
                                                        accept=".jpg, .jpeg, .png"
                                                        className="hide-input-plan-file-element"
                                                        onChange={(event) => {
                                                            addPreTreatmentPhotos(eachSide, event)
                                                        }}
                                                    />
                                                </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Col>
        )
    }


    const FirstSetOfAligners = () => {
        const { values, setFieldValue } = useFormikContext()
        const teethSides = ["aligner_front_teeth", "aligner_upper_jaw", 'aligner_lower_jaw', 'aligner_right_side', 'aligner_left_side']

        const removeSelectedImage = side => {
            setFieldValue(`first_set_of_aligners.${side}`, "")
        }

        const addFirstSetOfAligners = (eachSide, event) => {
            let file = event.target.files[0]
            const maxSize = 150 * 1024 * 1024; // max 150mb
            if (file && file.size > maxSize) {
                alert('File size should be less than 150mb');
                file = '';
            }
            const property = `first_set_of_aligners.${eachSide}`
            setFieldValue(property, file)

        }
        return (
            <Col>
                <div className='mt-3'>
                    <p className="text-center field-texts  mx-3">Add photos with 1<sup>st</sup> set of aligners (Optional) </p>
                    <div className='d-flex justify-content-between align-items-center mx-4 border border-3 bg-light  rounded-2 p-3'>
                        {
                            teethSides.map(eachSide => {
                                return (
                                    <div key={eachSide} className=' '>
                                        {
                                            values.first_set_of_aligners[eachSide] ?
                                                <div className='selected-active-image-container rounded'>
                                                    <button type="button" className="deleted-icon-of-image border-0 p-0 m-0" onClick={() => removeSelectedImage(eachSide)}> <MdCancel />  </button>
                                                    <img src={URL.createObjectURL(values.first_set_of_aligners[eachSide])} alt={eachSide} className='rounded' />
                                                </div> :
                                                <div className='selected-image-empty-container rounded'>
                                                    <p className='text-center text-capitalize'> {eachSide.split("_").join(" ")}  </p>
                                                    <label htmlFor={eachSide} className='add-image-plus-icon'> <MdAddCircleOutline />  </label>
                                                    <input
                                                        type="file"
                                                        id={eachSide}
                                                        name={eachSide}
                                                        accept=".jpg, .jpeg, .png"
                                                        className="hide-input-plan-file-element"
                                                        onChange={(event) => {
                                                            addFirstSetOfAligners(eachSide, event)
                                                        }}
                                                    />
                                                </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Col>
        )
    }

    return (
        <div className="sign-up-model-container">
            <div className="sign-up-model-form-container">
                <div className="popup-close-btn d-flex justify-content-end" style={{ margin: "-15px" }}>
                    <button className="bg-transparent border-0" onClick={() => { handleclose(); }} >
                        <img src={close} alt="close-btn" width="50px" height="50px"></img>
                    </button>
                </div>
                <div className="d-flex navbar_text_dashbord justify-content-center mb-3">
                    <div>
                        <FaUserPlus
                            size="30px"
                            color="#0090FF"
                            className="mx-3"
                        />
                    </div>
                    <div>Add User Details</div>
                </div>

                {snackbar && (
                    <SnackFire
                        open={snackbar}
                        onClose={() => { setsnackbar(false) }}
                        position="top-right"
                        timing={4000}
                        type={type}
                        message={snackbarmessage}
                    />
                )}
                {JSON.parse(localStorage.getItem("te")) && <TokenExpired />}
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ isSubmitting }) => (
                        <Form>
                            <Container>
                                <Row className='mb-3'>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75 '>
                                            <label htmlFor="firstName" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    First Name<span className='text-danger'>*</span>
                                                </span>
                                            </label>
                                            <Field type="text" id="firstName" name="firstName" className="form-input grey-bg-color" placeholder="First Name" />
                                            <ErrorMessage name="firstName" component="div" className='text-danger ms-3' />
                                        </div>
                                    </Col>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="lastName" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Last Name<span className='text-danger'>*</span>
                                                </span>
                                            </label>
                                            <Field type="text" id="lastName" name="lastName" className="form-input  grey-bg-color" placeholder="Last Name" />
                                            <ErrorMessage name="lastName" component="div" className='text-danger ms-3' />
                                        </div>
                                    </Col>

                                </Row>
                                <Row className='mb-3'>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="gender" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Gender<span className='text-danger'>*</span>
                                                </span>
                                            </label>
                                            <div className='d-flex justify-content-evenly align-items-center mt-2'>
                                                <label>  <Field type="radio" id="male" name="gender" value="male" />   Male</label>
                                                <label>  <Field type="radio" id="female" name="gender" value="female" /> Female  </label>
                                                <label>  <Field type="radio" id="other" name="gender" value="other" /> Other</label>
                                            </div>
                                            <ErrorMessage name="gender" component="div" className='text-danger ms-3' />
                                        </div>
                                    </Col>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="dob" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Date of Birth<span className='text-danger'>*</span>
                                                </span>
                                            </label>
                                            <Field type="date" id="dob" name="dob" className="form-input  grey-bg-color" placeholder="Date Of Birth" />
                                            <ErrorMessage name="dob" component="div" className='text-danger ms-3' />
                                        </div>
                                    </Col>

                                </Row>
                                <Row className='mb-3'>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="email" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Email<span className='text-danger'>*</span>
                                                </span>
                                            </label>
                                            <Field type="email" id="email" name="email" className="form-input grey-bg-color" placeholder="Email" />
                                            <ErrorMessage name="email" component="div" className='text-danger ms-3' />
                                        </div>
                                    </Col>

                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="mobileNumber" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Cell/Mobile Number<span className='text-danger'>*</span>
                                                </span>
                                            </label>
                                            <Field type="text" id="mobileNumber" name="mobileNumber" className="form-input grey-bg-color" placeholder="Cell/Mobile Number" />
                                            <ErrorMessage name="mobileNumber" component="div" className='text-danger ms-3' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <div>
                                                <label htmlFor="assignedDentist" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        Assign Dentist<span className='text-danger'>*</span>
                                                    </span>
                                                </label>
                                                {!isLoading && <div>
                                                    {doctors.length > 0 ? <Field as="select" id="assignedDentist" name="assignedDentist" placeholder="Select Dentist" className="form-input grey-bg-color" >
                                                        <option value=""> Select Dentist</option>
                                                        {doctors.map((eachDoctor) => {
                                                            return <option value={eachDoctor._id} key={eachDoctor._id} > {eachDoctor.name}</option>
                                                        })
                                                        }
                                                    </Field> : <p className='text-danger'>You need to add a dentist to create a user. <Link to="/dentist" >Click here </Link> to add a dentist</p>}
                                                </div>}
                                                <ErrorMessage name="assignedDentist" component="div" className='text-danger ms-3' />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="zipcode" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    ZIP/Postal Code<span className='text-danger'>*</span>
                                                </span>
                                            </label>
                                            <Field type="text" id="zipcode" name="zipcode" className="form-input grey-bg-color" placeholder="Zipcode" />
                                            <ErrorMessage name="zipcode" component="div" className='text-danger ms-3' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <TreatmentDate />
                                    <AlignerDays />

                                </Row>
                                <Row className='mb-3'>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75 '>
                                            <label htmlFor="total_aligners" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Total Number of Aligners<span className='text-danger'>*</span>
                                                </span>
                                            </label>
                                            <Field type="number" min="1" id="total_aligners" name="total_aligners" className="form-input grey-bg-color" placeholder="Total Number of Aligners" />
                                            <ErrorMessage name="total_aligners" component="div" className='text-danger ms-3' />
                                        </div>
                                    </Col>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <label htmlFor="current_aligner" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Current Aligner Number<span className='text-danger'>*</span>
                                                </span>
                                            </label>
                                            <Field type="number" min="0" id="current_aligner" name="current_aligner" className="form-input grey-bg-color" placeholder="Current Aligner Number" />
                                            <ErrorMessage name="current_aligner" component="div" className='text-danger ms-3' />
                                        </div>
                                    </Col>

                                </Row>
                                <Row className='mb-3'>
                                    <Col className='d-flex justify-content-center'>
                                        <div className='w-75'>
                                            <div>
                                                <label htmlFor="fileOption" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        Select Plan File<span className='text-danger'>*</span>
                                                    </span>
                                                </label>
                                                <Field as="select" id="fileOption" name="fileOption" placeholder="Select a Plan File" className="form-input grey-bg-color" >
                                                    <option value=""> Select Plan File</option>
                                                    <option value="upload">Upload a Video File</option>
                                                    <option value="webUrl">Web URL</option>
                                                </Field>
                                                <ErrorMessage name="fileOption" component="div" className='text-danger ms-3' />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='d-flex justify-content-center'>
                                        <UploadFileInput />
                                    </Col>
                                </Row>
                                <Row>
                                    <MovementChart />
                                </Row>
                                <Row>
                                    <PreTreatmentPhotos />
                                </Row>
                                <Row>
                                    <FirstSetOfAligners />
                                </Row>
                                <Row>
                                    <Col>
                                        <NextCheckDate days={initialValues.aligner_change_days} />
                                    </Col>
                                </Row>


                                <Row>
                                    <Col>
                                        <div className='d-flex justify-content-center mt-4'>
                                            <Button type="submit" className="form-btn mx-3" disabled={loader}>
                                                {loader ? <div className="text-center">
                                                    <div className="spinner-border text-white" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                </div> : <span> {submitButtonText} </span>}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>

    )
}

export default SignUpForm



