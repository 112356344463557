import React, { useState } from "react";

const ImagePreview = ({ file }) => {
  const [preview, setpreview] = useState(null);

  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = () => {
    setpreview(reader.result);
  };

  return (
    <div className=" d-flex justify-content-center w-5 bg-white">
      <img src={preview} className="apollo_image m-1 positions" alt="preview" />
    </div>

  );
};

export default ImagePreview;
