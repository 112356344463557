import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { IoIosArrowBack } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import SnackFire from "../../UI/Snackbar/Snackbar";
import { updateAlignerDetails, uploadVideoTos3Bucket } from "../../Services/AlignerService";
import TokenExpired from "../TokenExpired";
import { useParams } from "react-router-dom";


const AlignerUpdateForm = ({ alignerData, back, reload }) => {
    const {user_id } = useParams()

    const app = JSON.parse(localStorage.getItem("xld")).app;
    const appUrl = JSON.parse(localStorage.getItem("xld")).app_url
    const [submitButtonText, setText] = useState("Submit")
    const [loader, setLoader] = useState(false)
    const [snackbarmessage, setsnackbarmessage] = useState("");
    const [snackbar, setsnackbar] = useState(false);
    const [type, settype] = useState("error");

    const formattedDate = (nextDate) => {
        const currentDate = new Date(nextDate);
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const date = currentDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${date}`
    }


    const initialValues = {
        fileOption: alignerData?.file_option,
        file: "",
        videoName: alignerData?.video_name,
        fullUrl: alignerData?.full_url,
        webUrl: alignerData?.web_url,
        current_aligner: alignerData?.current_aligner,
        aligner_change_days: alignerData?.aligner_change_days,
        total_aligners: alignerData?.total_aligners,
        next_date_to_take_test: formattedDate(alignerData?.next_date_to_take_test),

    }

    const validationSchema = Yup.object().shape({
        fileOption: Yup.string().required('Required'),
        file: Yup.mixed().when('fileOption', {
            is: (fileOption) => fileOption === "upload" && !alignerData?.video_name,
            then: Yup.mixed().required('Please upload a file.').test('is-video', 'Invalid file format. Please upload a mp4 file.', (value) =>
                value && ['video/mp4', 'video/mpeg', 'video/ogg', 'video/webm', 'video/quicktime'].includes(value.type)
            ).test('fileSize', 'Video size is too large, please select a video of below 150 mb', (value) => !value || value.size <= 150 * 1024 * 1024),
        }),
        webUrl: Yup.mixed().when('fileOption', {
            is: 'webUrl',
            then: Yup.string().required('Required').url('Invalid URL.'),
        }),

        aligner_change_days: Yup.number().min(1, "Must be more than 0").required("Required"),
        total_aligners: Yup.number().min(1, "Must be more than 0").required("Required"),
        current_aligner: Yup.number().min(0, "Must be positive").required("Required"),
        next_date_to_take_test: Yup
            .date()
            .required('Required')
            .test('is-future-or-present', 'Date must be in the present or future', function (value) {
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0); 
                return value >= currentDate;
            }),
    })

    const updateAlignerInfo = (data) => {
        updateAlignerDetails({...data, aligner_id : alignerData._id}).then((res) => {
            setText("Updated successfully")
            setLoader(false)
            setsnackbarmessage("Aligner details updated successfully");
            settype("success");
            setsnackbar(true);
            reload()
            setTimeout(() => {
                back();
            }, 2000);

        }).catch((err) => {
            console.log(err)
            setLoader(false)
            setsnackbarmessage("Something went wrong please try again later.");
            settype("error");
            setsnackbar(true);
        })
    }

    const handleSubmit = (values) => {
        setLoader(true)
        const { fileOption, file } = values
        if (fileOption === "upload") {
            if (file) {
                const blob = new Blob([file], { type: file.type });
                const newFile = new File([blob], file.name, { type: file.type, lastModified: file.lastModified });
                const formData = new FormData();
                formData.append('video', newFile);
                uploadVideoTos3Bucket({ formData }).then((res) => {
                    if (JSON.stringify(res.data.data) === '{}') {
                        setLoader(false)
                        setsnackbarmessage(res.data.message);
                        settype("error");
                        setsnackbar(true);

                    } else {
                        const videoDetails = res.data.data
                        updateAlignerInfo({ ...values, ...videoDetails })
                    }
                }).catch((err) => {
                    setLoader(false)
                    setsnackbarmessage("Uploading mp4 file failed please try again with valid mp4 file");
                    settype("error");
                    setsnackbar(true);

                })

            } else {
                const fullUrl = alignerData?.full_url
                const videoName = alignerData?.video_name
                updateAlignerInfo({ ...values, fullUrl, videoName })

            }


        } else {
            updateAlignerInfo(values)
        }


    }

    const UploadFileInput = () => {
        const { values, setFieldValue } = useFormikContext();
        return (
            <>
                {values.fileOption === 'upload' && (
                    <div className=' d-flex flex-column justify-content-center align-items-center '>
                        <label htmlFor="file" className=' file-input bg-primary text-white rounded border border-primary py-1 px-3 ms-3 mt-4'>Browse a mp4 file   </label>
                        <input
                            type="file"
                            id="file"
                            name="file"
                            onChange={(event) => setFieldValue('file', event.currentTarget.files[0])}
                            accept="video/*"
                            className='hide-input-plan-file-element'
                        />
                        <div className="d-flex justify-content-center align-items-start">
                            {values.file && values.file?.name && <p className='text-success fw-bold'>  {values.file.name}  </p>}
                            {values.file && values.file?.name && <button type='button' className='bg-transparent border-0' onClick={() => { setFieldValue('file', '') }} > <MdCancel className='mb-4 shadow' />  </button>}
                       </div>                        
                        <ErrorMessage name="file" component="div" className="text-danger ms-3" />
                        {!values.file ? (values.videoName ? <p className="text-success fw-bold"> Recently added a mp4 file </p> : <p className='text-danger'>No File choosen</p>) : null}

                    </div>
                )
                }

                {values.fileOption === "webUrl" && (
                    <div className='w-75'>
                        <label htmlFor="webUrl" className="pt-2 font-weight-500 text-dark field-text mb-1">
                            <span className="field-texts  mx-3">
                                Web URL<span className="text-danger">*</span>
                            </span>
                        </label>
                        <Field type="text" id="webUrl" name="webUrl" className="form-input grey-bg-color" placeholder="Enter web url" />
                        <ErrorMessage name="webUrl" component="div" className='text-danger ms-3' />
                    </div>
                )
                }
            </>
        );
    };

    return (
        <div className="user-details-form-container">
            {snackbar && (
                <SnackFire
                    open={snackbar}
                    onClose={() => { setsnackbar(false) }}
                    position="top-right"
                    timing={4000}
                    type={type}
                    message={snackbarmessage}
                />
            )}
            {JSON.parse(localStorage.getItem("te")) && <TokenExpired />}
            <button type="button" className="bg-white rounded ms-4 border border-primary mt-4" onClick={() => { back(false) }} > <IoIosArrowBack className="mb-1" /> </button>

            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} >
                <Form>
                    <Container>                       
                        <Row>
                            <Col className='d-flex justify-content-center mb-3'>
                                <div className='w-75 '>
                                    <label htmlFor="total_aligners" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                        <span className="field-texts  mx-3">
                                            Total Number of Aligners<span className="text-danger">*</span>
                                        </span>
                                    </label>
                                    <Field type="number" min="1" id="total_aligners" name="total_aligners" className="form-input  grey-bg-color" placeholder="Total Number of Aligners" />
                                    <ErrorMessage name="total_aligners" component="div" className='text-danger ms-3' />
                                </div>
                            </Col>
                            <Col className='d-flex justify-content-center mb-3'>
                                <div className='w-75'>
                                    <label htmlFor="current_aligner" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                        <span className="field-texts  mx-3">
                                            Current Aligner Number<span className="text-danger">*</span>
                                        </span>
                                    </label>
                                    <Field type="number" min="0" id="current_aligner" name="current_aligner" className="form-input  grey-bg-color" placeholder="Current Aligner Number" />
                                    <ErrorMessage name="current_aligner" component="div" className='text-danger ms-3' />
                                </div>
                            </Col>

                        </Row>
                        <Row>
                        
                            <Col className='d-flex justify-content-center mb-3'>
                                <div className='w-75'>
                                    <label htmlFor="aligner_change_days" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                        <span className="field-texts  mx-3">
                                            Aligner Change Period(days)<span className="text-danger">*</span>
                                        </span>
                                    </label>
                                    <Field type="number" min="1" id="aligner_change_days" name="aligner_change_days" className="form-input grey-bg-color" placeholder="Number of Days" />
                                    <ErrorMessage name="aligner_change_days" component="div" className='text-danger ms-3' />
                                </div>
                            </Col>
                            <Col className='d-flex justify-content-center mb-3'>
                                        <div className='w-75'>
                                            <label htmlFor="next_date_to_take_test" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                <span className="field-texts  mx-3">
                                                    Next Check Date<span className="text-danger">*</span>
                                                </span>
                                            </label>
                                            <Field type="date" id="next_date_to_take_test" name="next_date_to_take_test" className="form-input  grey-bg-color" placeholder="Next check date" />
                                            <ErrorMessage name="next_date_to_take_test" component="div" className='text-danger ms-3' />
                                        </div>
                                    </Col>
                        </Row>
                        <Row>
                            <Col className='d-flex justify-content-center mb-3'>
                                <div className='w-75'>
                                    <div>
                                        <label htmlFor="fileOption" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                            <span className="field-texts  mx-3">
                                                Select Plan File<span className="text-danger">*</span>
                                            </span>
                                        </label>
                                        <Field as="select" id="fileOption" name="fileOption" placeholder="Select a plan file" className="form-input grey-bg-color" >
                                            <option value=""> Select Plan File</option>
                                            <option value="upload">Upload a Video File</option>
                                            <option value="webUrl">Web URL</option>
                                        </Field>
                                        <ErrorMessage name="fileOption" component="div" className='text-danger ms-3' />
                                    </div>
                                </div>
                            </Col>
                            <Col className='d-flex justify-content-center mb-3'>
                                <UploadFileInput />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='d-flex justify-content-center mt-4'>
                                <button type="submit" className="submit-button px-5 mb-4 border-0" >                                
                                        {loader ? <div className="text-center">
                                           <div className="spinner-border spinner-border-sm text-white mx-4" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div> : <span> {submitButtonText} </span>}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </Form>

            </Formik>
        </div>
    )
}

export default AlignerUpdateForm