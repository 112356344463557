import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Container from "react-bootstrap/Container";
import "../AlignerDashboard/alignerDashboard.css"
import { getAppLogo } from "../../Store/appLogo";

function Navbars(props) {
    const dispatch = useDispatch()
    const { app } = JSON.parse(localStorage.getItem("xld"))
    const { officeLogo, fetched } = useSelector((state) => state.appLogo)

    useEffect(() => {
        !fetched && dispatch(getAppLogo({ app }))

    }, [])

    return (
        <>
            <Container fluid className="pt-4 mb-4 aligncheck-navbar">
                <div className="">
                    {officeLogo &&
                        <div className="client-logo-dashboard">
                            <img
                                alt="app-logo"
                                src={`${officeLogo}`}
                                className="client-logo-dimensions"
                            />
                        </div>}
                    <h3 className="text-center text-primary font-DM-Sans">  {props.header ? props.header : "Aligncheck Dashboard"} </h3>
                </div>
            </Container>

        </>
    );
}

export default Navbars;
