import React, { useState } from "react";
import "./login.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../assets/ToothLensLogo.png";
import Button from "@mui/material/Button";
import ToothScan from "../../assets/scanTeeth.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import FormLabel from "@mui/material/FormLabel";
import { Link, useNavigate } from "react-router-dom";
import { forgotPasswordService } from "../../Services/AlignerService";
import SnackFire from "../../UI/Snackbar/Snackbar";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [snackbarmessage, setsnackbarmessage] = useState("");
  const [snackbar, setsnackbar] = useState(false);
  const [type, settype] = useState("error");

  const handleclose = () => {
    setsnackbar(false);
  };

  return (
    <Container fluid className="">
      {
        <Formik
          validate={(values) => {
            let errors = {};

            if (!values.email) {
              errors.email = "Email ID is required";
            }

            return errors;
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required(" Email is Required"),
          })}
          initialValues={{
            email: "",
          }}
          onSubmit={(values) => {
            forgotPasswordService(values)
              .then((response) => {
                if (response) {
                  navigate("/verify-code", { state: values });
                }
              })
              .catch((error) => {
                settype("error")
                setsnackbarmessage(error.response?.data?.message)
                setsnackbar(true)
                return error;
              });
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col
                    md={6}
                    xs={12}
                    className=" d-flex flex-column signup_image justify-content-center align-items-center"
                  >
                    <img
                      className="image-position"
                      src={ToothScan}
                      alt="Scan"
                    />
                    <div className="dashboard-footer d-flex  my-5 align-items-end height-footer">
                      Powered by Toothlens
                    </div>
                  </Col>

                  <Col
                    md={6}
                    xs={12}
                    className=" d-flex justify-content-center align-items-center position"
                  >
                    <div className="position-absolute">
                      <img src={Logo} className="images" alt="Logo" />
                    </div>

                    <div>
                      <Col md={6}>
                        <FormLabel
                          component="legend"
                          className=" font-weight-500 text-dark font-style"
                        >
                          <span className="field-texts">Email Address</span>
                        </FormLabel>
                      </Col>

                      <Col className="mt-1">
                        {" "}
                        <input
                          id="email"
                          className="form-input "
                          placeholder="johndow@gmail.com"
                          type="text"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.email}
                          </div>
                        )}
                      </Col>

                      <Col className="mt-4">
                        <Button className="form-btn" type="submit">
                          GET CODE
                        </Button>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      }
      {snackbar && (
        <SnackFire
          open={snackbar}
          onClose={handleclose}
          position="top-right"
          timing={5000}
          type={type}
          message={snackbarmessage}
        />
      )}
    </Container>
  );
};

export default ForgotPassword;
