import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik"
import ImagePreview from "./imagePreview"
import camera from "../../assets/camera.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Container, Row, Col } from "react-bootstrap";
import Button from "@mui/material/Button";
import SnackFire from "../../UI/Snackbar/Snackbar";



import * as Yup from "yup"
import { uploadLogoToS3Bucket, updateAppDetails } from "../../Services/AlignerService";
import "./officeDetails.css"
import TokenExpired from "../TokenExpired";
import { getAppDetails } from "../../Store/appDetails";
import { getAppLogo } from "../../Store/appLogo";

const FormPage = (props) => {
    const { back, appDetails } = props
    const dispatch = useDispatch();


    const app = JSON.parse(localStorage.getItem("xld")).app;
    const [snackbarmessage, setsnackbarmessage] = useState("");
    const [snackbar, setsnackbar] = useState(false);
    const [type, settype] = useState("error");
    const [loader, setLoader] = useState(false)
    const [submitButtonText, setSubmitButtonText] = useState("Save and update")



    const initialValues = {
        file: '',
        OfficeName: appDetails ? appDetails.app_name : "",
        OfficeEmail: appDetails ? appDetails.app_email : "",
        phoneNumber: appDetails ? appDetails.app_phone_number : "",
        city: appDetails ? appDetails.app_city : "",
        address: appDetails ? appDetails.app_address : "",
        state: appDetails ? appDetails.app_state : "",
        country: appDetails ? appDetails.app_country : "",
        zipCode: appDetails ? appDetails.app_zip_code : ""
    }

    const validationSchema = Yup.object().shape({
        file: Yup.mixed()
            .required('Please select an image')
            .test('fileFormat', 'Invalid file format. Only images are allowed.', (value) => {
                if (!value) return true; // If no file is selected, validation passes
                return ['image/jpeg', 'image/png'].includes(value.type);
            })
            .test('fileSize', 'Image size is too large. Maximum size allowed is 5MB.', (value) => {
                if (!value) return true; // If no file is selected, validation passes
                return value.size <= 5 * 1024 * 1024; // 5MB in bytes
            }),
        OfficeName: Yup.string().required("Dental Office name is required"),
        OfficeEmail: Yup.string().email('Invalid email').required('Email is required'),
        phoneNumber: Yup.string().required("Phone number is required"),
        address: Yup.string().required('Office Address is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        country: Yup.string().required('Country is required'),
        zipCode: Yup.string().required('Zipcode is required'),

    })

    const handleSubmit = async (values) => {
        const { file } = values
        if (values.file) {
            const blob = new Blob([file], { type: file.type });
            const newFile = new File([blob], "clinic_image.jpeg", { type: file.type, lastModified: file.lastModified });
            const formData = new FormData();
            formData.append('clinic_image', newFile);
            setLoader(true)
            await uploadLogoToS3Bucket({ formData }).then((res) => {
                const { imageName, fullUrl } = res.data
                const app_logo_file = imageName.split("/")[1]
                updateAppDetails({ ...values, app, app_logo_file, fullUrl }).then((res) => {
                    settype("success")
                    setsnackbarmessage(res.message);
                    setsnackbar(true);
                    setLoader(false)
                    setSubmitButtonText("Updated Successfully")
                    dispatch(getAppDetails({ app }))
                    dispatch(getAppLogo({ app }))
                    back()

                }).catch((err) => {
                    settype("error")
                    setsnackbarmessage("Something went wrong please try again later.");
                    setsnackbar(true);
                    setLoader(false)
                })

            }).catch((err) => {
                settype("error")
                setsnackbarmessage("Something went wrong please try again later.");
                setsnackbar(true);
                setLoader(false)
            })

        }


    }

    const Logo = () => {
        const { values, setFieldValue } = useFormikContext()

        return (
            <div className="apollo_image mt-5  positions">
                {!values.file && (
                    <div className="d-flex flex-column align-items-center justify-content-center upload_logo_text">
                        <div className="upload_logo_font">Upload logo </div>
                        <div className="mt-2">
                            (It will be published in the user app){" "}
                        </div>
                        <div className="mt-2">
                            Dimension: 500px x 500px{" "}
                        </div>
                    </div>
                )}

                {values.file && <ImagePreview file={values.file} />}

                <div className="absolute">
                    <label htmlFor="file">
                        <img src={camera} className="camera-icon" alt="logo" />
                    </label>
                    <input
                        type="file"
                        id="file"
                        className="my-3 custom-file-input"
                        accept=".jpg, .jpeg, .png"
                        name="file"
                        style={{ display: "none" }}
                        onChange={(event) => { setFieldValue("file", event.target.files[0]) }}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="bg-white w-100 rounded-3">
            {snackbar && (
                <SnackFire
                    open={snackbar}
                    onClose={() => { setsnackbar(false) }}
                    position="top-right"
                    timing={4000}
                    type={type}
                    message={snackbarmessage}
                />
            )}
            {JSON.parse(localStorage.getItem("te")) && <TokenExpired />}
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {(isSubmitting) => (
                    <Form>
                        <Container>
                            <Row>
                                <Col>
                                    <div>
                                        <button className=" form-back-button btn btn-primary mt-2 rounded-circle" onClick={() => { back() }}>
                                            <ArrowBackIcon className="my-1" />
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="">
                                    <Logo />
                                    <ErrorMessage name="file" component="div" className="text-danger mt-3" />
                                    <p className="text-success mt-2"> Image dimensions are automatically adjusted in the user app</p>


                                </Col>
                                <Col className="office-details-form-container">

                                    <Row >
                                        <Col className='d-flex justify-content-start'>
                                            <div className='w-75'>
                                                <label htmlFor="OfficeName" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        Dental Office Name*
                                                    </span>
                                                </label>
                                                <Field type="text" id="OfficeName" name="OfficeName" className="form-input mb-3 grey-bg-color" placeholder="Dental Office Name" />
                                                <ErrorMessage name="OfficeName" component="div" className='text-danger' />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col className='d-flex justify-content-start'>
                                            <div className='w-75'>
                                                <label htmlFor="phoneNumber" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        Phone Number*
                                                    </span>
                                                </label>
                                                <Field type="number" id="phoneNumber" name="phoneNumber" className="form-input mb-3 grey-bg-color" placeholder="Phone Number" />
                                                <ErrorMessage name="phoneNumber" component="div" className='text-danger' />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col className='d-flex justify-content-start'>
                                            <div className='w-75'>
                                                <label htmlFor="OfficeEmail" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        Office Email*
                                                    </span>
                                                </label>
                                                <Field type="text" id="OfficeEmail" name="OfficeEmail" className="form-input mb-3 grey-bg-color" placeholder="Office Email" />
                                                <ErrorMessage name="OfficeEmail" component="div" className='text-danger' />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col className='d-flex justify-content-start'>
                                            <div className='w-75'>
                                                <label htmlFor="address" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        Address*
                                                    </span>
                                                </label>
                                                <Field type="text" id="address" name="address" className="form-input mb-3 grey-bg-color" placeholder="Address" />
                                                <ErrorMessage name="address" component="div" className='text-danger' />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col className='d-flex justify-content-start'>
                                            <div className='w-75'>
                                                <label htmlFor="city" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        City*
                                                    </span>
                                                </label>
                                                <Field type="text" id="city" name="city" className="form-input mb-3 grey-bg-color" placeholder="City" />
                                                <ErrorMessage name="city" component="div" className='text-danger' />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col className='d-flex justify-content-start'>
                                            <div className='w-75'>
                                                <label htmlFor="state" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        State*
                                                    </span>
                                                </label>
                                                <Field type="text" id="state" name="state" className="form-input mb-3 grey-bg-color" placeholder="State" />
                                                <ErrorMessage name="state" component="div" className='text-danger' />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col className='d-flex justify-content-start'>
                                            <div className='w-75'>
                                                <label htmlFor="country" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        Country*
                                                    </span>
                                                </label>
                                                <Field type="text" id="country" name="country" className="form-input mb-3 grey-bg-color" placeholder="Country" />
                                                <ErrorMessage name="country" component="div" className='text-danger' />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row >
                                        <Col className='d-flex justify-content-start'>
                                            <div className='w-75'>
                                                <label htmlFor="zipCode" className="pt-2 font-weight-500 text-dark field-text mb-1">
                                                    <span className="field-texts  mx-3">
                                                        Zip Code/Pincode*
                                                    </span>
                                                </label>
                                                <Field type="number" id="zipCode" name="zipCode" className="form-input mb-3 grey-bg-color" placeholder="Zip code/Pincode" />
                                                <ErrorMessage name="zipCode" component="div" className='text-danger' />
                                            </div>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col className=" mt-3 mb-5">
                                            <Button className="form-btns-office-save-and-update mb-5" type="submit">
                                                {loader ? <div className="text-center">
                                                    <div className="spinner-border spinner-border-sm text-white mx-4" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                </div> : <span> {submitButtonText} </span>}
                                            </Button>
                                        </Col>
                                    </Row>

                                </Col>

                            </Row>
                        </Container>

                    </Form>
                )}

            </Formik>


        </div>
    )



}

export default FormPage