import axios from "axios";
import { API_HOST } from "./BaseUrl";

let refreshTokenTimeout;

const AxiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) =>
    status === 200 || status === 201 || status === 204,
});

const axiosJWT = axios.create();

axiosJWT.interceptors.response.use(
  (response) => {
    return response
  }, (error) => {
    if (error && error?.response?.status === 502) {
      localStorage.setItem("te", JSON.stringify(true))
    }
    return Promise.reject(error)

  }
)

export function stopRefreshTokenTimer() {
  const version = localStorage.getItem("es")
  localStorage.clear();
  clearTimeout(refreshTokenTimeout);
  version && localStorage.setItem("es", version)
}

export function refreshToken(refreshTokens, email) {
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(
        API_HOST + `admin/refresh-token`,
        { token: refreshTokens, email },
        { headers: headers }
      )
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("admin", JSON.stringify(response.data.data));
          localStorage.setItem("authToken", response.data.data.jwtToken);
          // startRefreshTokenTimer(response.headers.authorization, response.data.data.token);
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        stopRefreshTokenTimer();
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: "Network error", isError: true });
        }
      });
  });
}

export const registerAdmin = (request) => {                 //  under action  
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `admin/register`, request, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        if (error && error.response?.status === 406) {
          reject({ message: "User is Already Registerd!!!", isError: true });
        } else if (error && error.response?.status === 409) {
          reject({ message: "Something went wrong please try again later.", isError: true });
        }
      });
  });
};

export const verifyAggrementSignService = (request) => {                     //  under action
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `admin/aggrementverify`, request, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyCodeService = (request) => {                       //  under action  
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `admin/emailverify`, request, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const loginAdmin = (request) => {                    // under action
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `admin/login`, request, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          // startRefreshTokenTimer(response.headers.authorization, response.data.data.token);
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: "Server is disconnected", isError: true });
        }
      });
  });
};

export const forgotPasswordService = (request) => {                    // under action  
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `admin/forgotpassword`, request, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyForgotPasswordService = (request) => {              // under action
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `admin/verifyforgotpasswordcode`, request, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resendRegisterCodeService = (request) => {   // under action  
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `admin/resendcode`, request, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const rememberMe = () => {                        // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `getTokenDetails`, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAppDetails = (filters) => {                           // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `getAppDetails`, {
        params: filters,
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const createNewPasswordService = (request) => {                 // under action  
  const headers = {
    "Content-Type": "application/json",
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `admin/resetpassword`, request, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const clinicPic = (request) => {       // under action
  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  var blob = dataURItoBlob(request);
  var file = new File([blob], "clinic_image.jpeg", { type: "image/jpeg" });
  var formData = new FormData();
  if (file) {
    formData.append("clinic_image", file);
  }
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `admin/clinicPic`, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        return error;
      });
  });
};

export const clinicDetails = (data) => {               // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `adminClinicDetails`, data, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const userDetails = (data) => {                  // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `edental/updateUserProfile`, data, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getLatestClinicDetails = (filters) => {            // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `clinicDetails`, { params: filters, headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        console.log(error)
        reject(error);
      });
  });
};

export const getBinaryDataOfClinicImage = (params) => {        //  under actioin
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `binary-data`, { params, headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        }
        reject(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getUserDetails = (filters) => {     //  under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `edental/getindividualuserdetails`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logoutUserService = () => {                     // under action
  const authToken = localStorage.getItem("authToken");
  const refreshToken = JSON.parse(localStorage.getItem("tlnv"));
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .put(
        API_HOST + `admin/logout`,
        { token: refreshToken },
        { headers: headers }
      )
      .then((response) => {
        if (response.status === 200) {
          stopRefreshTokenTimer();
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        stopRefreshTokenTimer();
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: "Server is disconnected", isError: true });
        }
      });
  });
};



export const deleteDentistBasedOnId = (data) => {        //   under action
  const authToken = localStorage.getItem("authToken");

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `deleteDentistById`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const dentistDetailsBasedOnId = (filters) => {      // under action    
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `getDentistDetailsBasedOnId`,
        {
          params: filters,
          headers
        },
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const updateDentistDetails = (data) => {              //    under action
  const authToken = localStorage.getItem("authToken");
  const app = JSON.parse(localStorage.getItem("xld")).app;
  const sendingdata = { ...data, app };
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateDentistProfileById`, sendingdata, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const updateDentistNameFromOfficeDetails = (data) => {                // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateDentistNameFromOfficeDetails`, data, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const roleList = (filters) => {    //    under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(
        API_HOST + `rolesAvailable`,
        {
          params: filters,
          headers
        },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateDentistPasswordForFirstTime = (data) => {               // under action  
  const authToken = localStorage.getItem("authToken");
  const admin_id = JSON.parse(localStorage.getItem("admin")).admin._id;
  const sendingdata = { ...data, admin_id };
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updatePasswordForDentistForFirstTime`, sendingdata, { headers })
      .then((response) => {
        if (response) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const GetBucketFile = (fileData) => {          //  under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken

  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `review-report/get-file`, fileData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        if (error && error.response) {
          reject({ ...error.response, isError: true });
        } else {
          reject({ message: 'Network error', isError: true });
        }
      });
  });
};

export const uploadVideoTos3Bucket = ({ formData, app, userEmail }) => {                 //   under action
  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: authToken
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `uploadVideoTos3Bucket`, formData, {
        params: { app, userEmail },
        headers: headers
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response)
        }
        reject(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

}

export const signUpNewUser = (userDetails) => {                      // under action

  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken
  }

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `signUpNewUser`, userDetails, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response)
        }
        reject(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getAlignerUsers = (filters) => {                // under action
  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken
  }

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `getAlignerUser`, {
        params: filters,
        headers
      })
      .then((res) => {
        if (res.status === 200) {
          resolve(res)
        }
        reject(res)
      })
      .catch((err) => {
        console.log("error at authservice")
        reject(err)
      })
  })
}

export const getAlignerUserDetails = (userId) => {       /// under action 
  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken
  }

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `getAlignerUserDetails/${userId}`, { headers })
      .then((res) => {
        if (res.status === 200) {
          resolve(res)
        }
        reject(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateDentistforAlignerUer = (data) => {               // under action
  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken
  }

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateDentistforAlignerUer`, data, { headers })
      .then((res) => {
        if (res.status === 200) {
          resolve(res)
        }
        reject(res)
      }).catch((err) => {
        reject(err)
      })
  })

}

export const getAlignerUserReports = userId => {             // under actioin
  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken
  }
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `aligner-reports`, { params: { userId }, headers })
      .then((res) => {
        if (res.status === 200) {
          resolve(res)
        }
        reject(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getBinaryDataofVideo = (data) => {                        // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `getBinaryDataofVideo`, { params: data, headers })
      .then((res) => {
        if (res.status === 200) {
          resolve(res)
        }
        reject(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateReportFromDentist = (data) => {                                    // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateReportFromDentist`, data, { headers })
      .then((res) => {
        if (res.status === 200) {
          resolve(res)
        }
        reject(res)
      }).catch((err) => {
        reject(err)
      })
  })

}
export const updateReportViewedStatus = (data) => {                        // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `report-viewed`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        console.log(error)
        reject(error);
      });
  });
};        



export const updateUserToViewed = data => {                         // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateUserToViewed`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response)
        }
        reject(response)
      })
      .catch((err) => {
        reject(err)
      })
  })

}

export const updateAlignerUserProfile = (data) => {                         // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateAlignerUserProfile`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateAlignerDetails = (data) => {                         // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `update-aligner-details`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateAlignerUserFlow = (data) => {                         // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `updateAlignerUserFlow`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAlignerReportsForCsv = (filters) => {                         // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `getAlignerReportsForCsv`,
        {
          params: filters,
          headers
        })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addAlignerDoctor = (data) => {                          // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `addAlignerDoctor`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAlignerDoctors = (params) => {                          // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `getAlignerDoctors`, { params, headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllAlignerDoctors = (params) => {                          // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `getAllAlignerDoctors`, { params, headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const userTreatmentComplete = (data) => {                         // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `treatment-complete`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadLogoToS3Bucket = ({ formData }) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `admin/clinicPic`, formData, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const updateAppDetails = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `update-app-details`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error)
      });
  });

}

export const getAlignerAppDetails = (filters) => {                           // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `aligner-app-details`, {
        params: filters,
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response);
      })
      .catch((error) => {
        console.log(error.response)
        reject(error.response.data);
      });
  });
};

export const uploadFilesToS3Bucket = ({ email, app, formData }) => {                 //   under action
  const authToken = localStorage.getItem("authToken")
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: authToken
  };

  return new Promise((resolve, reject) => {
    return axiosJWT
      .post(API_HOST + `aligner/upload-files-s3`, formData, {
        params: { app, email },
        headers: headers
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response)
        }
        reject(response)
      })
      .catch((error) => {
        reject(error)
      })
  })

}


export const allAlignerUsers = (data) => {                           // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `all-aligner-users`, {
        params: data,
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAlignerPartnerVersion = () => {                           // under action
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `aligner-partner/version-details`, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const submitAligncheckScan = (data) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {

    return axiosJWT
      .post(API_HOST + `submit-aligncheck-scan`, data, {
        params: data,
        headers
      }).then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data)

      }).catch((err) => {
        reject(err)

      })


  });

}

export const getAlignerDetailsOfUser = (user_id) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  return new Promise((resolve, reject) => {
    return axiosJWT
      .get(API_HOST + `aligner-details`, { params: { user_id }, headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error)
      });
  });
}

export const getAlignerImages = (report_id) => {
  const authToken = localStorage.getItem("authToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  return new Promise((resolve, reject) => {
    return axiosJWT.get(API_HOST + `aligner-images`, { params: { report_id }, headers })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        }
        reject(response.data)
      }).catch((error) => {
        reject(error)
      })
  })

}