import * as React from 'react';
import { FaMailBulk } from "react-icons/fa";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { BiLogOut } from "react-icons/bi";
import { RiDashboardFill } from "react-icons/ri";
import { FaUserMd } from "react-icons/fa";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { logoutUserService } from "../../Services/AlignerService";
import Logout from '../LogOut';
import "./index.css"

const NavigationMenu = (props) => {
    const { active } = props
    const [isLogOut, setLogOut] = React.useState(false)

    return (
        <>
            <div className=" navigation-container d-flex flex-column justify-content-evenly align-items-center bg-white rounded-5 shadow me-2">
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{"Dashboard"}</Tooltip>}>
                    <Link
                        to="/aligner-dashboard"
                        style={{ textDecoration: "none", color: "inherit" }}

                    >
                        <RiDashboardFill className={`navigation-icons ${active === "dashboard" ? "active-tab" : ""}`} />   </Link >
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{"Office Details"}</Tooltip>}>
                    <Link
                        to="/account-details"
                        style={{ textDecoration: "none", color: "inherit" }}
                    >
                        <HiBuildingOffice2 className={`navigation-icons ${active === "office" ? "active-tab" : ""}`} />   </Link >
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{"Team Details"}</Tooltip>}>
                    <Link
                        to="/dentist"
                        style={{ textDecoration: "none", color: "inherit" }}
                    >
                        <FaUserMd className={` navigation-icons ${active === "team" ? "active-tab" : ""}`} /> </Link>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{"support@toothlens.com"}</Tooltip>}>
                    <a href="mailto:support@toothlens.com"
                        style={{ textDecoration: "none", color: "inherit" }}
                    >   <FaMailBulk className={`navigation-icons ${active === "support" ? "active-tab" : ""}`} />  </a>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{"Logout"}</Tooltip>}>
                    <button className='bg-transparent border-0' onClick={() => { setLogOut(true) }}>
                        <BiLogOut className={`navigation-icons ${active === "logout" ? "active-tab" : ""}`} />
                    </button >
                </OverlayTrigger>
            </div>
            {isLogOut && <Logout open={isLogOut} setLogout={setLogOut} />}
        </>

    )
}

export default NavigationMenu