import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./signUp.css";
import Logo from "../../assets/ToothLensLogo.png";
import ToothScan from "../../assets/scanTeeth.svg";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import FormLabel from "@mui/material/FormLabel";
import { registerAdmin } from "../../Services/AlignerService";
import Portal from "@mui/base/Portal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SnackFire from "../../UI/Snackbar/Snackbar";
import { useParams } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [snackbar, setsnackbar] = useState(false);
  const [type, settype] = useState("error");
  const [snackbarmessage, setsnackbarmessage] = useState("");
  let { signupReferral_id } = useParams();
  const handleclose = () => {
    setsnackbar(false);
  };
  return (
    <Container fluid>
      {
        <Formik
          validate={(values) => {
            let errors = {};
            // if (!values.dental_office_name) {
            //   errors.dental_office_name = "Dental Office Name is required";
            // }
            if (!values.first_name) {
              errors.first_name = "First Name is required";
            }
            if (!values.designation) {
              errors.designation = "Designation is required";
            }
            if (!values.password) {
              errors.password = "Password is required";
            }
            if (!values.email) {
              errors.email = "Email ID is required";
            }
            if (!values.confirm_password) {
              errors.confirm_password = "Confirm your password";
            }
            if (values.password != values.confirm_password) {
              errors.confirm_password = "Password do not match";
            }
            return errors;
          }}
          validationSchema={Yup.object().shape({
            first_name: Yup.string().required("First Name is required"),
            designation: Yup.string().required("Designation is required"),
            // dental_office_name: Yup.string().required("Dental Office Name is required"),
            password: Yup.string().required("Password is required"),
            email: Yup.string().email().required(" Email is Required"),
            confirm_password: Yup.string().required(
              "Please confirm your password"
            ),
          })}
          initialValues={{
            email: "",
            password: "",
            confirm_password: "",
            // dental_office_name:"",
            designation: "",
            first_name: "",
            last_name: "",
            signupReferral:signupReferral_id
          }}
          onSubmit={(values) => {
            registerAdmin(values)
              .then((response) => {
                if (response) {
                  setsnackbarmessage(response.message);
                  settype("success");
                  setsnackbar(true);
                  navigate("/signup-step-two", { state: values.email });
                }
              })
              .catch((error) => {
                setsnackbarmessage(error.message);
                settype("error");
                setsnackbar(true);
                return error;
              });
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            const handleClickShowPassword = () => {
              setshowPassword(!showPassword);
            };
            const handleClickShowConfirmPassword = () => {
              setshowConfirmPassword(!showConfirmPassword);
            };
            return (
              <form onSubmit={handleSubmit}>
                {snackbar && (
                  <SnackFire
                    open={snackbar}
                    onClose={handleclose}
                    position="top-right"
                    timing={5000}
                    type={type}
                    message={snackbarmessage}
                  />
                )}
                <Row>
                  <Col md={6} xs={12} className="signup_image">
                    <div className=" d-flex w-5 h-100 justify-content-center align-items-center">
                      <img
                        className="img-fluid rounded"
                        src={ToothScan}
                        alt="Scan"
                      />
                    </div>
                    <div className="image d-flex justify-content-center py-5 signup-footer">
                      Powered by Toothlens
                    </div>
                  </Col>

                  <Col
                    md={6}
                    xs={12}
                    className="d-flex align-items-center justify-content-center position"
                  >
                    <div className="position-absolute">
                      {/* <img src={Logo} className="images" alt="Logo" /> */}
                    </div>
                    <div>
                      <div className="d-flex w-5">
                        <Col md={6} className="mt-5">
                          <FormLabel
                            component="legend"
                            className="mt-4 font-weight-500 text-dark field-text"
                          >
                            <span className="field-texts  mx-3">
                              First Name
                            </span>
                          </FormLabel>
                        </Col>
                        <Col md={6} className="mt-5">
                          <FormLabel
                            component="legend"
                            className="mt-4 font-weight-500 text-dark field-text"
                          >
                            <span className="field-texts  mx-3">Last Name</span>
                          </FormLabel>
                        </Col>
                      </div>

                      <div className="d-flex w-5 ">
                        <Col md={6} className="mt-1 px-2 ">
                          <input
                            type="text"
                            id="first_name"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="first name"
                            value={values.first_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                        <Col md={6} className="mt-1 px-2">
                          <input
                            type="text"
                            id="last_name"
                            className="form-input mb-3 grey-bg-color"
                            placeholder=" last name"
                            value={values.last_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Col>
                      </div>
                      <div>
                        {" "}
                        {errors.first_name && touched.first_name && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.first_name}
                          </div>
                        )}
                      </div>

                      <Col>
                        <FormLabel
                          component="legend"
                          className="pt-2 font-weight-500 text-dark field-text"
                        >
                          <span className="field-texts  mx-3">Designation</span>
                        </FormLabel>
                      </Col>

                      <Col className="mt-1">
                        {" "}
                        <input
                          type="text"
                          id="designation"
                          className="form-input mb-3 grey-bg-color"
                          placeholder="designation"
                          value={values.designation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.designation && touched.designation && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.designation}
                          </div>
                        )}
                      </Col>
                      {/* <Col>
                    <FormLabel
                      component="legend"
                      className="pt-2 font-weight-500 text-dark field-text"
                    >
                      <span className="field-texts  mx-3"> Dental Office name</span>
                    </FormLabel>
                  </Col>
      
                  <Col className="mt-1">
                    {" "}
                    <input
                      type="text"
                      id="dental_office_name"
                      className="form-input mb-3 grey-bg-color"
                      placeholder="380 Dental"
                      value={values.dental_office_name}
                        onChange={handleChange}
                      onBlur={handleBlur}

                    />
                      {errors.dental_office_name && touched.dental_office_name && (
                    <div className="text-danger mb-2 ml-2">
                      {errors.dental_office_name}
                    </div>
                  )}
                  </Col> */}

                      <Col>
                        <FormLabel
                          component="legend"
                          className="ml-3 pt-2 pl-5 "
                        >
                          <span className=" field-texts mx-3">
                            {" "}
                            Email Address
                          </span>
                        </FormLabel>
                      </Col>

                      <Col className="mt-1">
                        {" "}
                        <input
                          id="email"
                          type="text"
                          className="form-input mb-3 grey-bg-color "
                          placeholder="johndow@gmail.com"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.email}
                          </div>
                        )}
                      </Col>

                      <Col>
                        <FormLabel
                          component="legend"
                          className="ml-3 pt-2 pl-5 font-weight-500 text-dark  "
                        >
                          <span className=" field-texts  mx-3">Password</span>
                        </FormLabel>
                      </Col>

                      <Col className="mt-1">
                        {" "}
                        <div className="position-relative">
                          <input
                            // type="text"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            className="form-input mb-3 grey-bg-color "
                            placeholder="Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span
                            className={`show-password`}
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </span>
                        </div>
                        {errors.password && touched.password && (
                          <div className="text-danger mb-2 ml-2">
                            {errors.password}
                          </div>
                        )}
                      </Col>

                      <Col>
                        <FormLabel
                          component="legend"
                          className="ml-3 pt-2 pl-5 font-weight-500 text-dark "
                        >
                          <span className="field-texts  mx-3">
                            Confirm Password
                          </span>
                        </FormLabel>
                      </Col>

                      <Col className="mt-1">
                        <div className="position-relative">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            id="confirm_password"
                            className="form-input mb-3 grey-bg-color"
                            placeholder="Confirm Password"
                            value={values.confirm_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <span
                            className={`show-password`}
                            onClick={handleClickShowConfirmPassword}
                          >
                            {showConfirmPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </span>
                        </div>

                        {errors.confirm_password &&
                          touched.confirm_password && (
                            <div className="text-danger mb-2 ml-2">
                              {errors.confirm_password}
                            </div>
                          )}
                      </Col>

                      <Col className="mt-3">
                        <Button className="form-btn" type="submit">
                          <span className="btn-text">SIGNUP</span>
                        </Button>
                      </Col>
                      <Col>
                        <FormLabel
                          component="legend"
                          className="ml-3 mt-3 pt-2 pl-5 font-weight-500 text-dark "
                        >
                          <span className="already-text">
                            {" "}
                            Already have an account.
                          </span>{" "}
                          <span className="login-link-text">
                            <Link to="/login"> Sign in</Link>
                          </span>
                        </FormLabel>
                      </Col>
                    </div>

                    {/* </div> */}
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      }
    </Container>
  );
};

export default SignUp;
